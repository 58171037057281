import { createSlice } from "@reduxjs/toolkit";
import React from "react";

const initialState = {
  savedOrders: [],
  savedOrderCount: 0,
  savedOrderFrom: 0,
  savedOrderTo: 0,
  savedPagination: 1,
  savedGroupedData: {},
  paginationLength: 0,
};

export const OrderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setSavedOrders: (state, action) => {
      state.savedOrders = action.payload;
    },
    setSavedOrderCount: (state, action) => {
      state.savedOrderCount = action.payload;
    },
    setSavedOrderFrom: (state, action) => {
      state.savedOrderFrom = action.payload;
    },
    setSavedOrderTo: (state, action) => {
      state.savedOrderTo = action.payload;
    },
    setSavedPagination: (state, action) => {
      state.savedPagination = action.payload;
    },
    setSavedGroupedData: (state, action) => {
      state.savedGroupedData = action.payload;
    },
    setPaginationLength: (state, action) => {
      state.paginationLength = action.payload;
    },
  },
});

export const {
  setSavedOrders,
  setSavedOrderCount,
  setSavedOrderFrom,
  setSavedOrderTo,
  setSavedPagination,
  setSavedGroupedData,
  setPaginationLength,
} = OrderSlice.actions;

export default OrderSlice.reducer;
