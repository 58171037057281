import React from "react";
import Navbar from "./Navbar";
import SidePanel from "./sidePanel/SidePanel";

function Layout() {
  return (
    <>
      <div className="container-scroller">
        <Navbar />
        <div className="container-fluid page-body-wrapper">
          <SidePanel />
        </div>
      </div>
    </>
  );
}

export default Layout;
