import { configureStore } from "@reduxjs/toolkit";
import AuthReducer from "./AuthSlice";
import OrderReducer from "./OrderSlice";
import StockReducer from "./StockSlice";

export default configureStore({
  reducer: {
    authorization: AuthReducer,
    order: OrderReducer,
    stockStore: StockReducer,
  },
});
