import React, { useEffect, useState } from "react";
import axiosInstance from "../app/axiosInstance";
import { useLocation, useNavigate } from "react-router-dom";
import { QueryClient } from "@tanstack/react-query";
import logo from "./images/dashboard/ultrastore-logo.png";
import { useDispatch, useSelector } from "react-redux";
import { setToken, setUser } from "../app/AuthSlice";
import CryptoJS from "crypto-js";
import env from "react-dotenv";
import { ToastContainer, toast } from "react-toastify";

const key = env.REACT_APP_ENCRYPTION_KEY;

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();

  useEffect(() => {
    state?.passwodChanged && changeNotification();
  }, []);

  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  const handleInputData = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    //sending data to the backend
    axiosInstance
      .post("/auth/login/", {
        username: formData.username,
        password: formData.password,
      })
      .then(function (response) {
        //still require error handling
        if (response.status === 200) {
          const token = response.data.data.token;
          const user = response.data.data.user;
          const userId = user.id;
          const user_group = user.groups[0].name;

          if (user_group === "shopowner") {
            const encryptedToken = CryptoJS.AES.encrypt(token, key).toString();
            localStorage.setItem("caffein", encryptedToken);
            localStorage.setItem("caffeinId", userId);
            dispatch(setToken(token));
            navigate("/");
          } else {
            toast.error(
              "Hauna ruhusa ya kuingia, tafadhari tumia application ya simu"
            );
          }
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast("taarifa ulizoingiza sio sahihi", {
            autoClose: true,
          });
        } else {
          toast(
            "kuna tatizo la kimtandao, jaribu tena au wasiliana na kitengo cha huduma kwa wateja"
          );
        }
      });
  };

  //notification
  const changeNotification = () =>
    toast.success(
      "umefanikiwa kubadirisha password kikamilifu, login upya tafadhali",
      { autoClose: true }
    );

  return (
    <div className="p-0 m-0 vh-100 w-100">
      <div className="h-100" style={{ background: "rgba(31,85,72,0.1)" }}>
        <div className="text-center d-flex justify-content-center align-items-center row h-100">
          <div className="col-sm-10 col-md-6 col-lg-4 align-items-center py-3 bg-light shadow">
            <div className="auth-form-light text-left p-5 auth">
              <div className="brand-logo d-flex justify-content-center">
                {" "}
                <img src={logo} alt="logo" />{" "}
              </div>
              <h4>Hello!</h4>
              <h6 className="font-weight-light">Login to continue.</h6>
              <form className="pt-3" onSubmit={handleFormSubmit}>
                <div className="form-group">
                  <input
                    type="text"
                    required
                    className="form-control form-control-lg"
                    id="loginUsername"
                    placeholder="Username"
                    name="username"
                    onChange={handleInputData}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="password"
                    required
                    className="form-control form-control-lg"
                    id="loginPassword"
                    placeholder="Password"
                    name="password"
                    onChange={handleInputData}
                  />
                </div>
                <div className="mt-4 font-weight-light">
                  {" "}
                  Forget password? <a className="text-primary">Reset</a>{" "}
                </div>
                <button
                  className="btn btn-block btn-success btn-lg font-weight-medium auth-form-btn my-3"
                  type="submit"
                >
                  LOGIN
                </button>
                <div className="mt-4 font-weight-light">
                  {" "}
                  Don't have an account? <a className="text-primary">
                    Create
                  </a>{" "}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer position="top-right" />
    </div>
  );
}

export default Login;
