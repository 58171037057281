import { mdiMagnify, mdiShopping, mdiSwapVerticalBold } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../app/axiosInstance";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../Components/loader";
import Footer from "../Components/footer";
import { ToastContainer, toast } from "react-toastify";
import PopUp from "../Components/popUp";
import PageHeader from "../Components/pageHeader";
import DateConverter from "../Components/DateConverter";
import { Badge } from "reactstrap";
import { setSavedGoodsUpdate } from "../../app/StockSlice";
import UpdateStockQuantity from "./updateStockQuantity";
import { setSavedStaff } from "../../app/AuthSlice";
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { Edit, Restore, rRestore } from "@mui/icons-material";

function StockItemView() {
  const token = useSelector((state) => state.authorization.token);
  const savedGoodsUpdate = useSelector(
    (state) => state.stockStore.savedGoodsUpdate
  );
  const savedQuantity = useSelector(
    (state) => state.authorization.savedQuantity
  );
  const savedStaff = useSelector((state) => state.authorization.savedStaff);
  const savedGoods = useSelector((state) => state.stockStore.savedGoods);
  const [good, setGood] = useState([]);
  const [goodsUpdate, setGoodsUpdate] = useState([]);
  const { state } = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [staff, setStaff] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const staffFetch = async () => {
      try {
        const response = await axiosInstance.get("/auth/user_registration/", {
          headers: {
            Authorization: `Token ${token}`,
          },
        });
        setStaff(response.data);
        dispatch(setSavedStaff(response.data));
      } catch (error) {}
    };

    if (savedStaff.length > 0) {
      setStaff(savedStaff);
    } else {
      staffFetch();
    }

    const goodFetch = async () => {
      try {
        const response = await axiosInstance.get(
          `/stock/goods/${state.goodIdData}/`,
          {
            headers: { Authorization: `Token ${token}` },
          }
        );
        setGood(response.data);
        setIsLoading(false);
      } catch (error) {}
    };
    goodFetch();

    if (savedGoodsUpdate?.length > 0 && state) {
      const updateFilter = savedGoodsUpdate.filter(
        (item) => item.goods === state?.goodIdData
      );
      setGoodsUpdate(updateFilter);
    }

    const goodsUpdateFetch = async () => {
      try {
        const response = await axiosInstance.get("/stock/stock_update/", {
          headers: {
            Authorization: `Token ${token}`,
          },
        });
        dispatch(setSavedGoodsUpdate(response.data.results));
        const filteredData =
          response.data.results?.length > 0 &&
          state &&
          response.data.results.filter(
            (item) => item.goods === state?.goodIdData
          );
        setGoodsUpdate(filteredData);
      } catch (error) {
        toast("kuna tatizo la kimtandao, jaribu tena");
      }
    };
    goodsUpdateFetch();
  }, [savedQuantity]);

  //Notification handling
  const Editing = ({ closeToast }) => (
    <div>
      unahitaji kurekebisha taarifa za bidhaa hii?
      <div>
        <button
          className="btn btn-success me-2"
          onClick={() => navigate("/item-add", { state: good })}
        >
          Ndio
        </button>
        <button onClick={closeToast} className="btn btn-danger me-2">
          Hapana
        </button>
      </div>
    </div>
  );
  const editConfirmation = () => toast(<Editing />);

  //action Badge color
  const badgeColor = (value) => {
    let color;
    value > 0 ? (color = "success") : (color = "danger");
    return color;
  };

  //return Human Readable goods data
  const getItem = (oneItem) => {
    if (savedGoods) {
      const good = savedGoods.filter((item) => item.id === oneItem);
      return good[0];
    } else {
      return [];
    }
  };

  //filter
  let word;
  let data;
  if (word?.length > 0) {
    data = data.filter((item) =>
      item.goods_number?.toLowerCase().includes(word.toLowerCase())
    );
  }

  //handle inputChange
  const [dateFilter, setDateFilter] = useState();
  const [toDateFilter, setToDateFilter] = useState();
  let dateRequired;
  let dateTo;

  const handleChange = (e) => {
    const { name, value } = e.target;
    const valueArr = value.replace(/-/g, " ").split(" ");

    const Required = new Date(`${valueArr[1]}/${valueArr[0]}/${valueArr[2]}`);

    valueArr[2]?.length === 4 && name == "from"
      ? (dateRequired = Required)
      : (dateRequired = new Date(0));

    valueArr[2]?.length === 4 && name == "to" && (dateTo = Required);
    setDateFilter(dateRequired);
    setToDateFilter(dateTo);
  };

  let searchFilteredData;

  if (dateFilter && goodsUpdate.length > 0) {
    searchFilteredData = goodsUpdate.filter((item) => {
      const itemDate = new Date(item.updated_on);
      return itemDate > dateFilter;
    });

    if (dateFilter && toDateFilter && goodsUpdate.length > 0) {
      searchFilteredData = goodsUpdate.filter((item) => {
        const itemDate = new Date(item.updated_on);
        return itemDate > dateFilter && itemDate < toDateFilter;
      });
    }
  } else {
    searchFilteredData = goodsUpdate;
  }

  //return human readable usernames
  const getName = (id) => {
    if (staff) {
      const staffName = staff.filter((item) => item.id === parseInt(id))[0]
        .username;
      return staffName;
    } else {
      return id;
    }
  };

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <div className="main-panel">
        <div className="content-wrapper">
          <PageHeader
            PageIcon={mdiShopping}
            requireBack={true}
            PageName={good.goods_number.toUpperCase()}
          />

          <div>
            <div className="mt-3">
              <div className="h4 p-2 text-secondary">Item details</div>
            </div>
          </div>

          <div className="row">
            <section className="py-3 bg-white mb-4">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 py-2">
                    <img
                      className="card-img-top mb-5 mb-md-0"
                      src={require("../images/dashboard/ImageIcon.jpg")}
                      alt="..."
                      style={{
                        height: "30rem",
                        objectFit: "cover",
                        objectPosition: "center",
                      }}
                    />
                  </div>
                  <div className="col-lg-6 py-2">
                    <div className="mb-1">
                      <Button>{good.goods_type}</Button>
                    </div>
                    <div className="py-3">
                      <h2 className="fw-bolder">
                        {good.goods_number.toUpperCase()}
                      </h2>
                      <h4>{good.goods_name}</h4>
                    </div>
                    <div>
                      <div className="row">
                        <div className="col-6  p-2">
                          <Box>
                            <Card className="bg-light">
                              <CardContent>
                                <div className="h3 text-center">
                                  {good.goods_available_for_sales}
                                </div>
                                <div className="text-small text-center">
                                  Available quantity
                                </div>
                              </CardContent>
                            </Card>
                          </Box>
                        </div>
                        <div className="col-6  p-2">
                          <Box>
                            <Card className="bg-light">
                              <CardContent>
                                <div className="h3 text-center">
                                  {good.lower_price_margin}
                                </div>
                                <div className="text-small text-center">
                                  Price per each
                                </div>
                              </CardContent>
                            </Card>
                          </Box>
                        </div>
                      </div>
                    </div>
                    <div className="py-3 w-100">
                      <div className="w-100">
                        <div className="w-100">
                          <div className="w-100 p-2 bg-light border-bottom mb-2">
                            Available in shops
                          </div>
                          <Stack direction="row" spacing={1}>
                            <Chip
                              label={good.store}
                              onClick={handleChange}
                              className="bg-light text-success"
                            />
                          </Stack>
                        </div>
                      </div>
                    </div>

                    <div className="py-2 w-100">
                      <IconButton disabled>
                        <Restore />
                      </IconButton>
                      <span className="ms-2">
                        {DateConverter(good.date_added)}
                      </span>
                    </div>
                    <div className="d-inline-flex gap-3">
                      <div className="">
                        <a className="text-decoration-none text-white">
                          <PopUp
                            popUpButtonLabelColor="success"
                            popUpButtonLabel="Edit"
                            popUpbody="Unahitaji kurekebisha taarifa za bidhaa hii?"
                            good={good}
                            isItemView={true}
                          />
                        </a>
                      </div>
                      <div className="l">
                        <a className="text-decoration-none text-white">
                          <UpdateStockQuantity
                            quantity={good.goods_available_for_sales}
                            itemId={good.id}
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row my-3">
                  <div className="w-100">
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <Tabs onChange={handleChange}>
                        <Tab label="Item history" />
                        <Tab label="Item description" />
                      </Tabs>
                    </Box>
                    <Container index={0}>Item 1</Container>
                    <Container index={0}>Item 2</Container>
                  </div>
                </div>
                <div className="row align-items-center mt-4">
                  <div className="col-6 p-3">
                    <div className="h4">Item History</div>
                    <div className="d-block">
                      <form className="d-flex align-items-center">
                        <div className="input-group rounded">
                          <div className="input-group-prepend">
                            <i className="input-group-text border-0 text-dark">
                              <Icon path={mdiMagnify} size={0.8} />
                            </i>
                          </div>
                          <input
                            type="text"
                            className="form-control bg-light border-0"
                            name="from"
                            onChange={handleChange}
                          />
                        </div>
                      </form>
                    </div>
                    <br />
                    <div className="d-block">
                      <form className="d-flex align-items-center">
                        <div className="input-group rounded">
                          <div className="input-group-prepend">
                            <i className="input-group-text border-0 text-dark">
                              <Icon path={mdiSwapVerticalBold} size={0.8} />
                            </i>
                          </div>
                          <input
                            type="text"
                            className="form-control bg-light border-0"
                            name="to"
                            placeholder="To Date ( 5-12-2023 )"
                            onChange={handleChange}
                          />
                        </div>
                      </form>
                    </div>
                    <br />
                  </div>
                  <div className="">
                    <table className="table table-responsive table-borderless table-hover">
                      <tr>
                        <th>Date</th>
                        <th>last Balance</th>
                        <th>Quantity change</th>
                        <th>Available</th>
                        <th>User</th>
                        <th>Action</th>
                      </tr>
                      {searchFilteredData.length > 0 ? (
                        searchFilteredData.map((item, index) => (
                          <tr className="text-start">
                            <td>{DateConverter(item.updated_on)} </td>
                            <td>{item.quantity_available}</td>
                            <td>{Math.abs(item.quantity_added)}</td>
                            <td>{item.balance}</td>
                            <td>{getName(item.updated_by)}</td>
                            <td className="text-white">
                              <Badge color={badgeColor(item.quantity_added)}>
                                {item.goods_update_reason.replace(/_/g, " ")}
                              </Badge>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <div className="text-center">
                          <h4>No History For This Item Yet</h4>
                        </div>
                      )}
                    </table>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        <Footer />
        <ToastContainer position="top-right" />
      </div>
    </>
  );
}

export default StockItemView;
