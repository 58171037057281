import Icon from "@mdi/react";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import NotifyMsg from "../Components/NotifyMsg";
import axiosInstance from "../../app/axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import { mdiWindowClose, mdiCheck } from "@mdi/js";
import EditGoodsOrder from "./EditGoodsOrder";
import { setSavedDeleteId } from "../../app/AuthSlice";

function GoodOrderTable({ orderGoods, orderDetails }) {
  const token = useSelector((state) => state.authorization.token);
  const savedGoods = useSelector((state) => state.stockStore.savedGoods);
  const savedOrderDetails = useSelector(
    (state) => state.authorization.savedOrderDetails
  );
  const savedDetailChange = useSelector(
    (state) => state.authorization.savedDetailChange
  );
  const [goods, setGoods] = useState();
  const [orderDetail, setOrderDetail] = useState(orderDetails);
  const [orderGood, setOrderGood] = useState(orderGoods);
  const [edited, setEdited] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setOrderDetail(orderDetail);
    setOrderGood(savedOrderDetails.order_goods_details);
  }, [savedOrderDetails]);

  const orderTotal = (goods) => {
    if (goods) {
      const priceTotal = () => {
        if (!goods) {
          return 0;
        }
        const sum = goods.reduce(
          (accumulator, item) => accumulator + item.sum_per_goods,
          0
        );
        return sum;
      };
      const itemTotal = () => {
        if (!goods) {
          return 0;
        }

        const sum = goods.reduce(
          (accumulator, item) => accumulator + item.goods_quantity,
          0
        );
        return sum;
      };
      const totalPrice = priceTotal();
      const totalItem = itemTotal();
      return { totalItem, totalPrice };
    }
  };

  //Order item issue
  let itemId;
  const issueNotification = (e) => {
    itemId = parseFloat(e.target.name);
    toast(
      <NotifyMsg
        Msg={"Mteja kakabidhiwa bidhaa hii?"}
        yesMethod={issueAction}
      />,
      {
        autoClose: false,
      }
    );
  };

  const issueAction = async () => {
    try {
      const response = await axiosInstance.put(
        `/order/update_goods_order/${itemId}/`,
        {
          goods_issue: {
            issued_status: true,
          },
        },
        {
          headers: {
            authorization: `Token ${token}`,
          },
        }
      );
      if (response.status === 200) {
        toast.dismiss();
        toast("Bidhaa hii imepunguzwa katika mfumo", {
          autoClose: true,
        });
        const orderFetch = async () => {
          try {
            const response = await axiosInstance.get(
              `/order/create_order/${orderDetail.order_no}`,
              {
                headers: {
                  Authorization: `Token ${token}`,
                },
              }
            );
            setOrderDetail(response.data);
            setOrderGood(response.data.order_goods_details);
          } catch (error) {}
        };
        orderFetch();
      }
    } catch (error) {
      toast("Kuna tatizo la mtandao, Jaribu tena", {
        autoClose: true,
      });
    }
  };

  //deleting goodsOrder
  let deleteId;
  const deleteGoodsOrderNotification = (e) => {
    deleteId = parseFloat(e.currentTarget.getAttribute("name"));
    toast(
      <NotifyMsg
        Msg={"unapunguza bidhaa hii(hizi) katika Order?"}
        yesMethod={deleteRequest}
      />,
      {
        autoClose: false,
      }
    );
  };

  const deleteRequest = async () => {
    toast.dismiss();
    toast("deleting item...", { autoClose: false });
    try {
      const response = await axiosInstance.delete(
        `/order/update_goods_order/${deleteId}/`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      if (response.status == 204) {
        toast.dismiss();
        toast.success("Bidhaa imetolewa katika Order kikamilifu", {
          autoClose: true,
        });
        const orderGoodsArr = orderGood;
        const newArr = orderGoodsArr.filter((item) => item.id !== deleteId);
        setOrderGood(newArr);
        setEdited(true);
        dispatch(setSavedDeleteId(deleteId));
      }
    } catch (error) {
      toast.dismiss();
      toast.error("kuna tatizo la kimtandao", { autoClose: true });
    }
  };

  //amount error on editing goodsOrger
  const amountError = () => {
    toast.error("idadi iliyoongezeka ni kubwa kuriko bidhaa zilizopo", {
      autoClose: true,
    });
  };

  const editGoodsOrder = async () => {
    const response = await axiosInstance.put();
  };

  return (
    <div className="p-3">
      <div className="card-header">
        <div className="h4">Products Details</div>
      </div>
      <div className="card-body my-0 py-0">
        <table className="table table-responsive table-hover">
          <tr>
            <th>Image</th>
            <th>Product</th>
            <th>Quantity</th>
            <th>Price/each</th>
            <th>Total Price</th>
          </tr>
          {/*order items to be displayed here */}
          {orderGood.map((item, index) => (
            <tr key={index} className="text-start">
              <td>
                <div
                  className="position-relative"
                  style={{ width: "4rem", height: "4rem" }}
                >
                  <img
                    alt="table-image"
                    src={require("../images/dashboard/ImageIcon.jpg")}
                    style={{
                      objectFit: "cover",
                    }}
                    className="rounded w-100 h-100"
                  />
                  {item.issued_status ? (
                    <div
                      className="position-absolute rounded-circle bg-success text-white d-flex align-items-center justify-content-center"
                      style={{
                        top: "0",
                        right: "0",
                        height: "1rem",
                        width: "1rem",
                      }}
                    >
                      <Icon path={mdiCheck} size={0.5} />
                    </div>
                  ) : (
                    <div
                      className="position-absolute rounded-circle bg-danger text-white d-flex align-items-center justify-content-center"
                      style={{
                        top: "0",
                        right: "0",
                        height: "1rem",
                        width: "1rem",
                      }}
                    >
                      <Icon path={mdiWindowClose} size={0.5} />
                    </div>
                  )}
                </div>
              </td>
              <td className="text-capitalize">{item.goods_number}</td>
              <td>{item.goods_quantity}</td>
              <td>{item.price.toLocaleString()}</td>
              <td>{item.sum_per_goods.toLocaleString()}</td>
              <td>
                {orderDetails.authorization_status === "paid" ? (
                  !item.issued_status && (
                    <span>
                      <button
                        className="btn btn-success"
                        name={item.id}
                        onClick={issueNotification}
                      >
                        Issue
                      </button>
                    </span>
                  )
                ) : (
                  <span>
                    <div className="d-inline-flex gap-0">
                      <div>
                        <EditGoodsOrder
                          values={item}
                          orderID={orderDetail.order_no}
                          amountError={amountError}
                        />
                      </div>
                      <div
                        onClick={deleteGoodsOrderNotification}
                        name={item.id}
                      >
                        <span className="text-decoration-none text-danger p-3">
                          <Icon path={mdiWindowClose} size={1} />
                        </span>
                      </div>
                    </div>
                  </span>
                )}
              </td>
            </tr>
          ))}
        </table>
      </div>
    </div>
  );
}

export default GoodOrderTable;
