import React from "react";
import { useNavigate } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiArrowLeft } from "@mdi/js";

function PageHeader({PageName, PageIcon, requireBack}) {
    const navigate = useNavigate()

    return (
        <div class="page-header">
            <h3 class="page-title">
                {requireBack && ( <span className="page-title-icon text-white shadow" style={{ marginRight: "2rem"}}>
                    <button type="button" className="btn text-white" style={{ background: "#00a79d" }} onClick={() => navigate(-1)}>
                        <Icon path={mdiArrowLeft} size={0.8} />
                    </button>
                </span>)}
               
                <span className="page-title-icon text-white me-2 shadow">
                    <i><Icon path={PageIcon} size={0.8}/></i>
                </span>{PageName}
            </h3>
        </div>
    )
};

export default PageHeader;