import React, { useEffect, useState } from "react";
import Goods from "./Goods";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../../app/axiosInstance";
import CustomLoader from "../CustomLoader";
import { setSavedGoods, setSearchWord } from "../../../app/StockSlice";
import { Skeleton, Fab, Box } from "@mui/material";
import Icon from "@mdi/react";
import { mdiArrowUp } from "@mdi/js";

function StockLayout({ title, isDashboard, goodsTypes }) {
  const token = useSelector((state) => state.authorization.token);
  const filteredGoodsType = useSelector(
    (state) => state.authorization.filteredPosition
  );
  const savedGoods = useSelector((state) => state.stockStore.savedGoods);
  const word = useSelector((state) => state.stockStore.searchWord) || "";
  const [loading, setIsLoading] = useState(true);
  const [stockIsLoading, setStockIsLoading] = useState(true);
  const [goodsData, setGoodsData] = useState([]);
  const [goodsUpdate, setGoodsUpdate] = useState([]);

  let data = [];
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSearchWord(""));

    //goodsData returned here will be the goods available for sales or updates done on different goods

    if (savedGoods.length > 0) {
      setGoodsData(savedGoods);
      setIsLoading(false);
      setStockIsLoading(false);
    }

    const goodsAvailable = async () => {
      try {
        const response = await axiosInstance.get("/stock/goods/", {
          headers: {
            Authorization: `Token ${token}`,
          },
        });
        //compare the fetch data with the available data
        dispatch(setSavedGoods(response.data.result));
        setGoodsData(response.data.result);
        setStockIsLoading(false);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    goodsAvailable();
  }, []);

  //helper function to return goods details based on item id as the key from the goodsUpdate details
  const getItem = (oneItem) => {
    if (goodsData) {
      const good = goodsData.filter((item) => item.id === oneItem.goods);
      return good[0];
    } else {
      return [];
    }
  };

  //filtering data based on DropDown selection and return them as data array
  if (goodsData) {
    if (filteredGoodsType !== "all") {
      data = goodsData.filter((item) => item.goods_type === filteredGoodsType);
    } else {
      data = goodsData;
    }

    //filtering the search results
    if (word.length > 0) {
      data = data.filter((item) =>
        item.goods_number?.toLowerCase().includes(word.toLowerCase())
      );
    }
  }

  //method to change budge color on stock less than 10%

  const defineBadge = (itemCount) => {
    let badgeClass = "bg-success";
    if (goodsData) {
      const totalValue = goodsData.reduce(
        (accumulator, item) => accumulator + item.goods_quantity,
        0
      );
      const averageNumber = parseInt(totalValue / goodsData.length);
      itemCount < parseInt(averageNumber * 0.1) && (badgeClass = "bg-danger");
    }
    return badgeClass;
  };

  const updateBadge = (quantityAdded) => {
    let badgeClass = "bg-success";
    quantityAdded < 0 && (badgeClass = "bg-danger");
    return badgeClass;
  };

  return (
    <div className="row py-2">
      <div className="col-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            {!isDashboard ? (
              <h4></h4>
            ) : (
              <div className="py-2 px-3 bg-light border-bottom">
                <h4>Recent updated Products</h4>
              </div>
            )}
            <div className="row">
              {stockIsLoading ? (
                <div>
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    height={150}
                    className="my-3 p-3"
                  />
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    height={150}
                    className="my-3 p-3"
                  />
                </div>
              ) : (
                !loading && (
                  <>
                    {isDashboard
                      ? data
                          .slice(0, 8)
                          .map((item) => (
                            <Goods
                              goodId={item.id}
                              name={item.goods_number}
                              lowerPrice={item.lower_price_margin.toLocaleString()}
                              quantity={item.goods_available_for_sales}
                              badgeClass={
                                item.goods_available_for_sales <
                                item.alert_on_goods_quantity
                                  ? "bg-danger"
                                  : "bg-success"
                              }
                            />
                          ))
                      : data.map((item) => (
                          <Goods
                            goodId={item.id}
                            name={item.goods_number}
                            lowerPrice={item.lower_price_margin.toLocaleString()}
                            quantity={item.goods_available_for_sales}
                            badgeClass={
                              item.goods_available_for_sales <
                              item.alert_on_goods_quantity
                                ? "bg-danger"
                                : "bg-success"
                            }
                          />
                        ))}
                  </>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StockLayout;
