import React, { useEffect, useState } from "react";
import Footer from "../Components/footer";
import { mdiBookmarkCheck, mdiMagnify } from "@mdi/js";
import Table from "../Components/table/Table";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../app/axiosInstance";
import Loader from "../Components/loader";
import DropDownFilter from "../Components/dropDownFilter/DropDownFilter";
import { setFilteredPosition } from "../../app/AuthSlice";
import { setSearchWord } from "../../app/StockSlice";
import PageHeader from "../Components/pageHeader";
import { Link, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Icon from "@mdi/react";
import { Pagination } from "@mui/material";
import { setSavedPagination } from "../../app/OrderSlice";
import API from "../../services/api/API";

function Orders() {
  const token = useSelector((state) => state.authorization.token);
  const savedOrderFrom = useSelector((state) => state.order.savedOrderFrom);
  const savedOrderTo = useSelector((state) => state.order.savedOrderTo);
  const paginationLength = useSelector((state) => state.order.paginationLength);
  const { state } = useLocation();
  const [orderStatus, setOrderStatus] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const statusArr = ["all"];
  const filteredPosition =
    useSelector((state) => state.authorization.filteredPosition) || "all";
  const dispatch = useDispatch();

  useEffect(() => {
    state?.isAdded &&
      toast.success("Order imetumwa kikamilifu", { position: "top-right" });

    //preventing the filtered dropDown selected from other pages
    dispatch(setFilteredPosition("all"));

    //point saved pagination to the initial page everytime the page loaded again
    dispatch(setSavedPagination(1));

    //fetching the order status to be used for filtering data
    const fetchOrderStatus = async () => {
      try {
        const response = await API.fetchOrderStatus(token);
        setOrderStatus(response.data.results);
        setIsLoading(false);
      } catch (error) {}
    };
    fetchOrderStatus();

    //clear state after consuming it to prevent reNotification on page reload
    window.history.replaceState(null, document.title, window.location.href);
  }, []);

  if (orderStatus) {
    orderStatus.map((item) => statusArr.push(item.status));
  }

  if (state) {
    state?.isDeleted &&
      toast.success("Order imefutwa katika mfumo", {
        position: "top-right",
        autoClose: true,
      });
  }
  const successNotification = () =>
    toast.success("Order imetumwa kikamilifu", { position: "top-right" });

  const deleteOrderNotification = () =>
    toast.success("Order imefutwa katika mfumo", {
      position: "top-right",
      autoClose: true,
    });

  //managing search filter
  const searchFilter = (e) => {
    const word = e.target.value;
    dispatch(setSearchWord(word));
  };

  //handling pagination
  const handlePagination = (e) => {
    dispatch(setSavedPagination(e.target.innerText));
  };

  return isLoading ? (
    <Loader />
  ) : (
    <div className="main-panel">
      <div class="content-wrapper">
        <PageHeader PageName="Orders" PageIcon={mdiBookmarkCheck} />
        <div class="d-inline-flex justify-content-between w-100 align-items-center">
          {/*here stay the filter */}
          <DropDownFilter linkName={statusArr} position={filteredPosition} />
          <div className="d-block">
            <form className="d-flex align-items-center">
              <div className="input-group rounded">
                <div className="input-group-prepend">
                  <i className="input-group-text border-0 text-dark">
                    <Icon path={mdiMagnify} size={0.8} />
                  </i>
                </div>
                <input
                  type="text"
                  className="form-control bg-light border-0"
                  placeholder="Search order number"
                  onChange={searchFilter}
                />
              </div>
            </form>
          </div>
          <div class="text-center">
            <Link to={"/create-order"} className="nav-link">
              <button type="button" className="btn btn-success">
                Create new order
              </button>
            </Link>
          </div>
        </div>
        <div className="d-flex pt-3 justify-content-between">
          <div>
            order #:{" "}
            <span className="fw-bolder text-success">
              {savedOrderTo} - {savedOrderFrom}
            </span>
          </div>
          <div>
            <Pagination
              count={paginationLength}
              color="success"
              onChange={handlePagination}
            />
          </div>
        </div>

        <div className="">
          <Table isDashboard={false} />
        </div>
      </div>
      <Footer />
      <ToastContainer position="top-right" />
    </div>
  );
}

export default Orders;
