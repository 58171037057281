import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../../app/axiosInstance";
import {
  Cell,
  Pie,
  PieChart,
  ResponsiveContainer,
  Legend,
  Tooltip,
} from "recharts";
import CustomLoader from "../CustomLoader";
import { setSavedGoodsTypes } from "../../../app/StockSlice";
import { ToastContainer, toast } from "react-toastify";
import colors from "../colors";
import { Box, Skeleton } from "@mui/material";

function CustomPieChart() {
  const token = useSelector((state) => state.authorization.token);
  const savedGoodsTypes = useSelector(
    (state) => state.stockStore.savedGoodsTypes
  );
  const savedGoods = useSelector((state) => state.stockStore.savedGoods);
  const [isLoading, setIsLoading] = useState(true);
  const [goodsData, setGoodsData] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    //using the cashed goodsTypes or fetching them (Deprecated For Now)
    if (savedGoodsTypes.length > 0) {
      const newDict = [];
      savedGoodsTypes.map((item) =>
        newDict.push({
          name: item.goods_type,
          value: item.goods_and_type.length,
        })
      );
    }
    const goodsTypeFetch = async () => {
      try {
        const response = await axiosInstance.get("/stock/goods_type/", {
          headers: {
            Authorization: `Token ${token}`,
          },
        });
        //updating the goodstypes whatever posibble
        dispatch(setSavedGoodsTypes(response.data));
        const newDict = [];
        response.data.map((item) =>
          newDict.push({
            name: item.goods_type,
            value: item.goods_and_type.length,
          })
        );
        setGoodsData(newDict);
      } catch (error) {
        console.log(error);
        toast("Samahani kuna tatizo la kimtandao", {
          autoClose: true,
        });
      }
    };

    //pieChart using goods details group by stores

    if (savedGoods.length > 0) {
      //item count by store
      const storeItemCount = {};
      savedGoods.forEach((element) => {
        if (!storeItemCount[element.store_details.store_name]) {
          storeItemCount[element.store_details.store_name] = 0;
        }
        storeItemCount[element.store_details.store_name] +=
          element.goods_available_for_sales;
      });

      //converting the storeItemCount data to the pie required Data
      const requiredArr = Object.entries(storeItemCount).map(
        ([name, value]) => ({
          name,
          value,
        })
      );
      setGoodsData(requiredArr);
      setIsLoading(false);
    }

    const goodsFetch = async () => {
      try {
        const response = await axiosInstance.get("/stock/goods/", {
          headers: {
            Authorization: `Token ${token}`,
          },
        });

        //item count by store
        const storeItemCount = {};
        response.data.result.forEach((element) => {
          if (!storeItemCount[element.store_details.store_name]) {
            storeItemCount[element.store_details.store_name] = 0;
          }
          storeItemCount[element.store_details.store_name] +=
            element.goods_available_for_sales;
        });

        //converting the storeItemCount data to the pie required Data
        const requiredArr = Object.entries(storeItemCount).map(
          ([name, value]) => ({
            name,
            value,
          })
        );
        setGoodsData(requiredArr);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    goodsFetch();
  }, []);

  return isLoading ? (
    <div className="row justify-content-center" style={{ height: "20rem" }}>
      <div className="my-3 col-12 justify-content-center">
        <div style={{ width: "12rem", height: "12rem" }}>
          <Skeleton
            variant="circular"
            animation="wave"
            className="w-100 h-100"
          />
        </div>
      </div>
      <div className="my-3 col-12 justify-content-center">
        <div style={{ width: "12rem", height: "12rem" }}>
          <Skeleton
            variant="rectangular"
            animation="wave"
            height={25}
            className="my-1"
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            height={25}
            className="my-1"
          />
          <Skeleton
            variant="rectangular"
            animation="wave"
            height={25}
            className="my-1"
          />
        </div>
      </div>
    </div>
  ) : (
    <ResponsiveContainer width="100%" height={350}>
      <PieChart>
        <Tooltip />
        <Legend />
        <Pie data={goodsData} dataKey="value" fill="#8884d8">
          {goodsData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors()[index]} />
          ))}
        </Pie>
      </PieChart>
      <ToastContainer position="top-center" />
    </ResponsiveContainer>
  );
}

export default CustomPieChart;
