import React from "react";

function Loader() {
  return (
    <div
      className="position-fixed vh-100 w-100 d-flex justify-content-center align-items-center"
      style={{
        top: "0",
        left: "0",
        zIndex: "9999",
      }}
    >
      <div className="position-relative" style={{width: "40px", height: "40px"}}>
                <div className="position-absolute opacity-75" style={{top: "0", left: "0"}}><img className="w-100" style={{objectFit: "contain"}} alt="logo" src={require("../images/dashboard/logo.png")} /></div>
                <div className="position-absolute loaderUS" style={{top: "0", left: "0"}}><img className="w-100" style={{objectFit: "contain"}} alt="logo" src={require("../images/dashboard/logo.png")} /></div>
            </div>
    </div>
  );
}

export default Loader;
