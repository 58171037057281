import { mdiArrowDown, mdiArrowTopLeft, mdiArrowUp } from "@mdi/js";
import Icon from "@mdi/react";
import React from "react";
import { Link } from "react-router-dom";

function Goods({
  goodId,
  name,
  lowerPrice,
  quantity,
  action,
  actionQuantity,
  badgeClass,
}) {
  const absoluteActionQuantity = Math.abs(actionQuantity);
  const data = { goodIdData: goodId };
  return (
    <div className="col-sm-6 .col-md-4 col-lg-3">
      <a className="text-decoration-none text-dark">
        <div className="p-3">
          <Link to={"/item-view"} state={data} className="nav-link">
            <div className="position-relative p-0 m-0">
              <img
                alt="item1"
                style={{ height: "9rem", objectFit: "cover" }}
                className="w-100 hoverable"
                src={require("../../images/dashboard/ImageIcon.jpg")}
              />
              <div 
              className="position-absolute px-1 text-info rounded bg-light" 
              style={{ top: "0.2rem", right: "0.2rem", zIndex: "5", }}>
                <div className="d-inline-flex gap-0 align-items-center">
                  <div><Icon path={mdiArrowDown} size={0.6} /></div>
                  <div className="text-small">{}</div>
                </div>
              </div>

            </div>
            <div className="mt-2 w-100 position-relative">
              <div className="h6 text-truncate m-0 p-0 font-weight-light w-75">
                {name}
              </div>
              <div className="h6 text-truncate m-0 p-0 text-small text-secondary">Tsh. {lowerPrice} </div>
              <div className="h6 text-truncate m-0 p-0">
                {action ? `Quantity ${action}: ${absoluteActionQuantity}` : ""}
              </div>
              <div
                className={`position-absolute p-1 rounded ${badgeClass} text-white`}
                style={{ top: "0", right: "0", zIndex: "3"}}
              >
                <div
                  className="rounded text-end"
                >
                  <div className="h6 m-0 p-1 font-weight-light w-70">
                    {quantity}
                  </div>
                </div>
              </div>

            </div>
          </Link>
        </div>
      </a>
    </div>
  );
}

export default Goods;
