import React, { useEffect, useState } from "react";
import Icon from "@mdi/react";
import Footer from "../Components/footer";
import { mdiAccount, mdiMenuDown } from "@mdi/js";
import { useDispatch, useSelector } from "react-redux";
import StaffCard from "./StaffCard";
import axiosInstance from "../../app/axiosInstance";
import DropDownLinks from "../Components/dropDownFilter/DropDownLinks";
import DropDownFilter from "../Components/dropDownFilter/DropDownFilter";
import { setFilteredPosition, setSavedStaff } from "../../app/AuthSlice";
import PageHeader from "../Components/pageHeader";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import NotifyMsg from "../Components/NotifyMsg";

function Staffs() {
  const token = useSelector((state) => state.authorization.token);
  const savedStaff = useSelector((state) => state.authorization.savedStaff);
  const [staffs, setStaffs] = useState([]);
  const { state } = useLocation();
  const location = useLocation();
  const filteredPosition =
    useSelector((state) => state.authorization.filteredPosition) || "all staff";
  const dropDown = ["all staff", "salesperson", "cashier", "storekeeper"];
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setFilteredPosition("all staff"));
    const staffFetch = async () => {
      try {
        const response = await axiosInstance.get("/auth/user_registration/", {
          headers: {
            Authorization: `Token ${token}`,
          },
        });
        setStaffs(response.data);
        dispatch(setSavedStaff(response.data));
      } catch (error) {}
    };
    staffFetch();
    window.history.replaceState(null, document.title, window.location.href);
  }, []);

  if (filteredPosition !== "all staff") {
    var filteredData = staffs.filter(
      (item) => item.groups[0] === filteredPosition
    );
  } else {
    var filteredData = staffs;
  }

  //Method to direct to staff add page
  const toUserRegistration = () => {
    toast.dismiss();
    navigate("/addStaff");
  };

  const addNotification = () => {
    toast(
      <NotifyMsg
        Msg={"unaelekea kuongeza staff mpya?"}
        yesMethod={toUserRegistration}
      />,
      {
        autoClose: false,
      }
    );
  };

  //deleting state
  const successfullAddStaff = () => {
    toast.success("Umeongeza Staff kikamilifu", { autoClose: true });
  };

  const successfullChangePassword = () => {
    toast.success("Umefanikiwa kubadili password kikamilifu", {
      autoClose: true,
    });
  };

  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <PageHeader PageName="Staffs" PageIcon={mdiAccount} />
        <div className="d-inline-flex justify-content-between w-100 align-items-center">
          <DropDownFilter position={filteredPosition} linkName={dropDown} />

          <div className="text-center">
            <a className="text-decoration-none text-white">
              <button
                type="submit"
                className="btn btn-success me-2"
                onClick={addNotification}
              >
                Ongeza mfanyakazi
              </button>
            </a>
          </div>
        </div>
        <div class="container-fluid rounded-top p-3">
          <div class="row">
            {filteredData.map((staff) => (
              <StaffCard user={staff} />
            ))}
          </div>
        </div>
      </div>
      <Footer />
      {state && state?.addedStaff && successfullAddStaff()}
      {state && state?.passwordChanged && successfullChangePassword()}
      <ToastContainer position="top-right" />
    </div>
  );
}

export default Staffs;
