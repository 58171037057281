import React, { useEffect, useState } from "react";
import TableData from "./TableData";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../../app/axiosInstance";
import CustomLoader from "../CustomLoader";
import { setSavedOrders } from "../../../app/AuthSlice";
import { setSearchWord } from "../../../app/StockSlice";
import {
  setPaginationLength,
  setSavedGroupedData,
  setSavedOrderCount,
  setSavedOrderFrom,
  setSavedOrderTo,
} from "../../../app/OrderSlice";
import axios from "axios";
import { error } from "jquery";
import { Skeleton } from "@mui/material";
import API from "../../../services/api/API";

function Table({ isDashboard }) {
  const token = useSelector((state) => state.authorization.token);
  const savedOrders = useSelector((state) => state.order.savedOrders);
  const word = useSelector((state) => state.stockStore.searchWord);
  const savedOrderCount = useSelector((state) => state.order.savedOrderCount);
  const savedPagination = useSelector((state) => state.order.savedPagination);
  const savedGroupedData = useSelector((state) => state.order.savedGroupedData);
  const [fetchedData, setFetchedData] = useState([]);
  const [groupedData, setGroupedData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [dataChange, setDataChange] = useState(true);
  const [asyncLoading, setAsyncLoading] = useState(true);
  const filteredPosition =
    useSelector((state) => state.authorization.filteredPosition) || "all";
  const dispatch = useDispatch();

  useEffect(() => {
    let initialData;

    dispatch(setSearchWord(""));

    //setFetchedData based on the required page number(pagination)
    if (Object.keys(savedGroupedData)?.length > 0) {
      const pageData = savedGroupedData[savedPagination];
      if (pageData) {
        setFetchedData(pageData);
        setIsLoading(false);
      }
    }

    //fetching initial data, savedOrderPagination=1
    const orderFetch = async () => {
      let group = savedGroupedData;
      try {
        await axiosInstance
          .get("/order/create_order/", {
            headers: {
              Authorization: `Token ${token}`,
            },
          })
          .then((response) => {
            initialData = response.data.results;
            const count = response.data.count;
            dispatch(setSavedOrderCount(count));

            //change the number based on the API pagination size
            const paginationCount = Math.ceil(count / 100);
            dispatch(setPaginationLength(paginationCount));

            let nextUrl;
            let nextUrlPage;
            //Working with pagination
            if (parseInt(savedPagination) === 1) {
              nextUrl = response.data?.next?.toString().split("/");
              nextUrlPage =
                nextUrl && nextUrl[nextUrl?.length - 1].split("=")[1];

              const from = initialData[0]?.order_no;
              const to = initialData[initialData?.length - 1]?.order_no;

              dispatch(setSavedOrderFrom(from));
              dispatch(setSavedOrderTo(to));

              //add the initialData in grouped data
              group = { ...group, [savedPagination]: initialData };

              //comparing the data if there is update, save the new update
              setFetchedData(response.data.results);
              dispatch(setSavedOrders(response.data.results));
              setIsLoading(false);
              setAsyncLoading(false);
            }

            return nextUrlPage;
          })
          .then(async (nextUrlPage) => {
            //Fetching the Next page and store its Data
            if (nextUrlPage) {
              const page = nextUrlPage;
              await axiosInstance
                .get(`/order/create_order/?page=${page}`, {
                  headers: {
                    Authorization: `Token ${token}`,
                  },
                })
                .then((response) => {
                  group = { ...group, [page]: response.data.results };
                  setGroupedData(group);
                  dispatch(setSavedGroupedData(group));
                })
                .catch((error) => console.log(error));
            }
          });
      } catch (error) {
        console.log(error);
      }
    };
    orderFetch();

    //fetching the specific Page data
    const pageFetch = async (page) => {
      let group = groupedData;
      try {
        await axiosInstance
          .get(`/order/create_order/?page=${page}`, {
            headers: {
              Authorization: `Token ${token}`,
            },
          })
          .then((response) => {
            let nextUrl;
            let nextUrlPage;
            let prevUrl;
            let prevUrlPage;

            const currentData = response.data.results;
            const count = response.data.count;

            //capture the first and last order number
            const from = currentData[0]?.order_no;
            const to = currentData[currentData?.length - 1]?.order_no;

            dispatch(setSavedOrderFrom(from));
            dispatch(setSavedOrderTo(to));

            //update the current page data after async fetch
            setFetchedData(currentData);
            setIsLoading(false);

            nextUrl = response.data?.next?.toString().split("/");
            nextUrlPage = nextUrl && nextUrl[nextUrl?.length - 1].split("=")[1];

            prevUrl = response.data?.previous?.toString().split("/");
            prevUrlPage = prevUrl && prevUrl[prevUrl?.length - 1].split("=")[1];

            //add the current page data into the grouped data
            group = { ...group, [page]: currentData };
            return { nextUrlPage, prevUrlPage };
          })
          .then(async (data) => {
            //fetch and save the next page data
            if (data.nextUrlPage) {
              const page = data.nextUrlPage;
              await axiosInstance
                .get(`/order/create_order/?page=${page}`, {
                  headers: {
                    Authorization: `Token ${token}`,
                  },
                })
                .then((response) => {
                  group = { ...group, [page]: response.data.results };
                })
                .catch((error) => console.log(error));
            }

            //Fetch and save the previous Page data
            if (data.prevUrlPage) {
              const page = data.prevUrlPage;
              await axiosInstance
                .get(`/order/create_order/?page=${page}`, {
                  headers: {
                    Authorization: `Token ${token}`,
                  },
                })
                .then((response) => {
                  group = { ...group, [page]: response.data.results };
                })
                .catch((error) => console.log(error));
            }

            setGroupedData(group);
            dispatch(setSavedGroupedData(group));
          });
      } catch (error) {
        console.log(error);
      }
    };

    if (savedPagination > 1) {
      pageFetch(savedPagination);
    }
  }, [savedPagination, dataChange]);

  //filtering data based on dropdown selection
  let filteredData;

  if (filteredPosition !== "all") {
    filteredData = fetchedData.filter(
      (item) => item.authorization_status === filteredPosition
    );
  } else {
    filteredData = fetchedData;
  }

  //Search filter
  if (word?.length > 0 && filteredData) {
    filteredData = filteredData.filter((item) =>
      item?.order_no.toString().includes(word)
    );
  }

  //removing the deleted order from the list
  const sendDataToParent = (data) => {
    setDataChange(!dataChange);
    filteredData = filteredData.filter((item) => item?.order_no !== data);
    setFetchedData(filteredData);
  };

  return isLoading ? (
    <>
      <div className="row py-2">
        <div className="col-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body bg-white">
              <h4 className="card-title">
                {isDashboard ? "Latest 5 Orders" : "Orders"}
              </h4>
              <Skeleton
                variant="rectangular"
                animation="wave"
                height={200}
                className="my-3 p-3"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <div className="row py-2">
      <div className="col-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body bg-white">
            {!isDashboard ? (
              <h4></h4>
            ) : (
              <div className="py-2 px-3 bg-light border-bottom">
                <h4>Last 5 orders</h4>
              </div>
            )}

            <div>
              <table className="table table-responsive table-hover text-start">
                <tr>
                  <th>Image</th>
                  <th>Date</th>
                  <th>Order number</th>
                  <th>Items</th>
                  <th>Price</th>
                  <th>Status</th>
                  <th></th>
                </tr>
                {isDashboard
                  ? filteredData
                      .slice(0, 5)
                      .map((item) => (
                        <TableData
                          column1Data={item.order_no}
                          column2Data={item.order_goods_details.length}
                          column3Data={item.total_order_amount?.order_total.toLocaleString()}
                          column4Data={item.authorization_status}
                          isDashboard={isDashboard}
                          dateAdded={item.date_added}
                        />
                      ))
                  : asyncLoading
                  ? filteredData.map((item) => (
                      <TableData
                        column1Data={item.order_no}
                        column2Data={item.order_goods_details.length}
                        column3Data={item.total_order_amount?.order_total.toLocaleString()}
                        column4Data={item.authorization_status}
                        isDashboard={isDashboard}
                        dateAdded={item.date_added}
                        sendData={sendDataToParent}
                      />
                    ))
                  : filteredData.map((item) => (
                      <TableData
                        column1Data={item.order_no}
                        column2Data={item.order_goods_details.length}
                        column3Data={item.total_order_amount?.order_total.toLocaleString()}
                        column4Data={item.authorization_status}
                        isDashboard={isDashboard}
                        dateAdded={item.date_added}
                        sendData={sendDataToParent}
                      />
                    ))}
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Table;
