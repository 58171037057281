import React, { useEffect, useState } from "react";
import { mdiDelete, mdiEye } from "@mdi/js";
import Icon from "@mdi/react";
import { Link, useNavigate } from "react-router-dom";
import DateConverter from "../DateConverter";
import AutoSizer from "react-virtualized-auto-sizer";
import { Badge } from "@mui/material";

function ItemsData({ index, style, data }) {
  const navigate = useNavigate();

  const toOrder = () => {
    navigate("/item-view", { state: { goodIdData: data.id } });
  };

  let badgeClass;
  if (data?.goods_available_for_sales < data?.alert_on_goods_quantity) {
    badgeClass = "bg-danger";
  } else {
    badgeClass = "bg-success";
  }

  return (
    <div style={style} className="table-row">
      <tr className="text-start hoverable table-row">
        <td onClick={toOrder} className="table-cell">
          <div className="bg-transparent">
            <div>
              <img
                alt="table-image"
                src={require("../../images/dashboard/ImageIcon.jpg")}
                style={{
                  width: "4rem",
                  height: "4rem",
                  objectFit: "cover",
                }}
                className="rounded"
              />
            </div>
          </div>
        </td>
        <td onClick={toOrder} className="table-cell text-wrap">
          {data?.goods_number}
        </td>
        <td onClick={toOrder} className="table-cell text-wrap">
          {data?.goods_name}
        </td>
        <td onClick={toOrder} className="table-cell">
          {data?.lower_price_margin?.toLocaleString()}
        </td>
        <td onClick={toOrder} className="table-cell">
          <div className="d-flex justify-content-center">
              <div
            className={`p-1 rounded ${badgeClass} text-white text-center w-25`}
          > {data?.goods_available_for_sales} </div></div>
        </td>
        <td className="table-cell">
          <div className="btn btn-group p-0 m-0 bg-transparent border-0">
            <a className="text-decoration-none btn">
              <Link
                to={"/item-view"}
                state={{ goodIdData: data?.id }}
                className="nav-link"
              >
                <i className="text-success p-3">
                  <Icon path={mdiEye} size={0.8} />
                </i>
              </Link>
            </a>
          </div>
        </td>
      </tr>
    </div>
  );
}

export default ItemsData;
