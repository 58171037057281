import React, { useEffect, useState } from "react";
import PageHeader from "../Components/pageHeader";
import { mdiAccountCog } from "@mdi/js";
import Footer from "../Components/footer";
import PopUp from "../Components/popUp";
import { useSelector } from "react-redux";
import PasswordValidation from "../Components/PasswordValidation";
import axiosInstance from "../../app/axiosInstance";
import ToastNetworkError from "../Components/ToastNetworkError";
import { ToastContainer, toast } from "react-toastify";
import LogOut from "../LogOut";
import { useLocation, useNavigate } from "react-router-dom";

function ChangePassword() {
  const token = useSelector((state) => state.authorization.token);
  const user = useSelector((state) => state.authorization.userData);
  const { state } = useLocation();
  const navigate = useNavigate();
  const [errorFields, setErrorField] = useState({
    currentPassword: true,
    password1: true,
    password2: true,
  });

  const [passData, setPassData] = useState({
    currentPassword: "",
    password1: "",
    password2: "",
  });

  //handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrorField({ ...errorFields, [name]: true });
    if (name === "password1" && PasswordValidation(value)) {
      setPassData({ ...passData, [name]: value });
    } else {
      setPassData({ ...passData, [name]: value });
    }
  };

  const editNotification = (e) => {
    e.preventDefault();
    const errorUpdates = { ...errorFields };

    //matching the password1 and password2
    if (passData.password1 !== passData.password2) {
      setErrorField({ ...errorUpdates, ["password2"]: false });
    }

    //obtaining the token for changing user specific password
    //NOTE:: the only important Data on Changing the password are the user id passed into url,
    //and password data. token only required for authorization, can be shopowner password as ussual

    let postUser;

    //this can be deprecated after update, prev password is checked on backend
    const checkingTheCurrentPassword = () => {
      const username = state ? state?.username : user.username;
      const userLogin = async () => {
        try {
          const response = await axiosInstance.post("/auth/login/", {
            username: username,
            password: passData.currentPassword,
          });
          if (response.status === 200) {
            postUser = response.data.data;
            changePassword();
          }
        } catch (error) {
          if (error.response.status === 400) {
            toast.error(
              "umeweka password isiyo sahihi, weka password sahihi kabla ya kubadirisha"
            );
            setErrorField({ ...errorFields, ["currentPassword"]: false });
          }
        }
      };
      userLogin();
    };

    const changePassword = () => {
      const request = async () => {
        try {
          const response = await axiosInstance.put(
            `/auth/change_password/${postUser.user.id}/`,
            {
              password: passData.password1,
              password2: passData.password2,
              old_password: passData.currentPassword,
            },
            {
              headers: {
                Authorization: `Token ${token}`,
              },
            }
          );
          if (response.status === 200) {
            if (state) {
              navigate("/staffs", { state: { passwordChanged: true } });
            } else {
              localStorage.removeItem("caffein");
              navigate("/login", { state: { passwodChanged: true } });
            }
          }
        } catch (error) {
          ToastNetworkError();
        }
      };
      request();
    };

    //Return false if no error at all
    const itemsValidation = () => {
      const keys = Object.keys(passData);
      keys.map((item, index) => {
        const itemName = keys[index];

        passData[itemName] !== ""
          ? (errorUpdates[itemName] = true)
          : (errorUpdates[itemName] = false);
      });
      setErrorField(errorUpdates);
      const errors = Object.keys(errorUpdates);
      return errors.some((error) => errorUpdates[error] === false);
    };

    if (!itemsValidation()) {
      checkingTheCurrentPassword();
    }
  };

  return (
    <div class="main-panel">
      <div class="content-wrapper">
        <PageHeader
          PageName="Profile"
          PageIcon={mdiAccountCog}
          requireBack={true}
        />
        <div>
          <div>
            <div class="h4 text-secondary">Edit Profile</div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <form class="forms-sample">
                  <div class="row flex-row-reverse">
                    <div class="col-md-6 d-flex justify-content-center"></div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="exampleInputPassword4">
                          current password{" "}
                          {!errorFields.currentPassword && (
                            <span className="text-danger">
                              required/wrong password
                            </span>
                          )}
                        </label>
                        <input
                          type="password"
                          class="form-control"
                          id="exampleInputPassword4"
                          name="currentPassword"
                          onChange={handleChange}
                          placeholder="********"
                        />
                      </div>
                      <div class="form-group">
                        <label for="exampleInputPassword4">
                          Change password{" "}
                          {!errorFields.password1 && (
                            <span className="text-danger">required</span>
                          )}
                        </label>
                        <input
                          type="password"
                          class="form-control"
                          id="exampleInputPassword4"
                          onChange={handleChange}
                          name="password1"
                          placeholder="********"
                        />
                      </div>
                      <div class="form-group">
                        <label for="exampleInputPassword4">
                          Confirm password{" "}
                          {!errorFields.password2 && (
                            <span className="text-danger">required</span>
                          )}
                        </label>
                        <input
                          type="password"
                          class="form-control"
                          id="exampleInputPassword4"
                          onChange={handleChange}
                          name="password2"
                          placeholder="********"
                        />
                      </div>
                    </div>
                  </div>
                  <button
                    className="btn btn-success"
                    onClick={editNotification}
                  >
                    Tuma mabadiriko
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <ToastContainer position="top-right" />
    </div>
  );
}

export default ChangePassword;
