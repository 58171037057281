import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../app/axiosInstance";
import Icon from "@mdi/react";
import {
  mdiBookmarkCheck,
  mdiPencil,
  mdiWindowClose,
  mdiPrinter,
  mdiDelete,
} from "@mdi/js";
import Loader from "../Components/loader";
import { useLocation, useNavigate, Link } from "react-router-dom";
import DateConverter from "../Components/DateConverter";
import GoodOrderTable from "./GoodOrderTable";
import Footer from "../Components/footer";
import { ToastContainer, toast } from "react-toastify";
import NotifyMsg from "../Components/NotifyMsg";
import PageHeader from "../Components/pageHeader";
import { Badge } from "reactstrap";
import { useReactToPrint } from "react-to-print";
import { ComponentToPrint } from "../Components/orderToPrint";
import {
  setSavedDetailChange,
  setSavedOrderDetails,
} from "../../app/AuthSlice";
import API from "../../services/api/API";

function OrderView(props) {
  const token = useSelector((state) => state.authorization.token);
  const savedDetailChange = useSelector(
    (state) => state.authorization.savedDetailChange
  );
  const savedDeleteId = useSelector(
    (state) => state.authorization.savedDeleteId
  );
  const [orderGoods, setOrderGoods] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [orderDetails, setOrderDetails] = useState([]);
  const [authorized, setAuthorized] = useState(false);
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const orderId = state.orderId;

  useEffect(() => {
    const orderDataFetch = async () => {
      try {
        const response = await API.orderData({ token, orderId });
        setOrderGoods(response.data.order_goods_details);
        setOrderDetails(response.data);
        dispatch(setSavedOrderDetails(response.data));
        setIsLoading(false);
      } catch (error) {
        toast("kuna tatizo la kimtandao, jaribu Tena", {
          autoClose: true,
        });
      }
    };
    orderDataFetch();
  }, [savedDeleteId, savedDetailChange]);

  //Notifications based on action
  let orderAction;
  const authorizeNotification = () => {
    orderAction = "paid";
    toast(
      <NotifyMsg
        Msg={"Je umepokea kiasi sahihi na upo tayari kuipitisha order Hii?"}
        yesMethod={authorization}
      />,
      {
        autoClose: false,
      }
    );
  };

  const editNotification = () => {
    toast(
      <NotifyMsg
        Msg={"Unahitaji kurekebisha order hii?"}
        yesMethod={editRedirect}
      />,
      {
        autoClose: false,
      }
    );
  };

  const cancelNotification = () => {
    orderAction = "cancel";
    toast(
      <NotifyMsg Msg={"Unasitisha order hii?"} yesMethod={authorization} />,
      { autoClose: false }
    );
  };

  const deleteNotification = () =>
    toast(
      <NotifyMsg
        Msg={"Order hii itafutwa katika mfumo, unauhakika na kitendo hiki?"}
        yesMethod={deleteAction}
      />,
      { autoClose: false }
    );

  //Actions
  const authorization = async () => {
    toast.dismiss();
    toast("Tafadhali Subili...", { autoClose: true });
    let actionToServer;
    if (orderAction) {
      orderAction === "paid" && (actionToServer = "paid");
      orderAction === "cancel" && (actionToServer = "canceled");
    }

    try {
      const response = await API.orderAuthorization({
        token,
        actionToServer,
        orderId,
      });
      if (response.status === 200) {
        toast.dismiss();
        setOrderDetails(response.data);
        setOrderGoods(response.data.order_goods_details);
        setAuthorized(true);
        toast("Malipo ya bidhaa yamepokelewa", {
          autoClose: true,
        });
      }
    } catch (error) {
      toast.error("Kuna Tatizo la kimtandao. Jaribu tena", { autoClose: true });
    }
  };

  //redirect to the edit page
  const editRedirect = () => {
    navigate("/create-order", { state: { orderDetails } });
  };

  const deleteAction = async () => {
    const order_no = orderDetails.order_no;

    try {
      const response = await API.deleteOrder({ token, order_no });
      if (response.status === 200) {
        navigate("/orders", { state: { isDeleted: true } });
      }
    } catch (error) {
      toast("imeshindikana kufuta order hii, Jaribu tena");
    }
  };

  //badge color based on authorization status
  let badgeColor = "info";
  if (orderDetails) {
    orderDetails.authorization_status === "paid" && (badgeColor = "success");
    orderDetails.authorization_status === "canceled" && (badgeColor = "danger");
  }

  //Printing methods
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <div className="main-panel">
        <div className="content-wrapper">
          <PageHeader PageName="Order detail" PageIcon={mdiBookmarkCheck} />
          <div>
            <div className="d-flex justify-content-between p-2">
              <div className="h4 text-secondary">Order details</div>
              <div className="">
                <Link to={"/create-order"} className="nav-link">
                  <button type="button" className="btn btn-success">
                    Create new order
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <section>
            <div className="row">
              <div className="col-12 px-3">
                <div className="bg-white rounded p-3">
                  <div className="p-3 row border-bottom">
                    <div className="col">
                      <div className="h3">
                        Order #{orderDetails.order_no}{" "}
                        <span>
                          <Badge color={badgeColor}>
                            {orderDetails.authorization_status}
                          </Badge>
                        </span>
                      </div>
                      <div className="text-secondary">
                        <span>Created by </span>
                        {orderDetails.creator} on{" "}
                        {DateConverter(orderDetails.date_added)}
                      </div>
                    </div>
                    <div className="col text-end">
                      {orderDetails.authorization_status === "paid" && (
                        <div>
                          <button
                            className="btn btn-success m-1"
                            onClick={handlePrint}
                          >
                            Print
                            <span hidden>
                              <ComponentToPrint
                                ref={componentRef}
                                data={orderDetails}
                              />
                            </span>
                            <span className="px-1">
                              <Icon path={mdiPrinter} size={1} />
                            </span>
                          </button>
                          <button
                            className="btn btn-danger m-1"
                            onClick={deleteNotification}
                          >
                            <Icon path={mdiDelete} size={1} />
                          </button>
                        </div>
                      )}
                      {orderDetails.authorization_status === "pending" && (
                        <div>
                          <button
                            className="btn btn-success m-1"
                            onClick={authorizeNotification}
                          >
                            Approve
                          </button>
                          <button
                            className="btn btn-info m-1"
                            onClick={editNotification}
                          >
                            <Icon path={mdiPencil} size={1} />
                          </button>
                          <button
                            className="btn btn-danger m-1"
                            onClick={cancelNotification}
                          >
                            <Icon path={mdiWindowClose} size={1} />
                          </button>
                        </div>
                      )}
                      {orderDetails.authorization_status === "canceled" && (
                        <div>
                          <button
                            className="btn btn-danger m-1"
                            onClick={deleteNotification}
                          >
                            <Icon path={mdiDelete} size={1} />
                          </button>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="row px-3 my-2">
                    <div
                      className="col m-2 p-2 rounded border"
                      style={{ background: "rgba(0, 167, 156, 0.235)" }}
                    >
                      <span className="text-secondary">Total Price</span>
                      <div className="h4 pt-2">
                        {orderDetails?.total_order_amount?.order_total.toLocaleString()}
                      </div>
                    </div>

                    <div
                      className="col m-2 p-2 rounded border"
                      style={{ background: "rgba(0, 167, 156, 0.235)" }}
                    >
                      <span className="text-secondary">VAT</span>
                      <div className="h4 pt-2">
                        {orderDetails.total_order_amount?.VAT.toLocaleString()}
                      </div>
                    </div>

                    <div
                      className="col m-2 p-2 rounded border"
                      style={{ background: "rgba(0, 167, 156, 0.235)" }}
                    >
                      <span className="text-secondary">Number of products</span>
                      <div className="h4 pt-2">
                        {orderDetails.order_goods_details.reduce(
                          (acc, item) => acc + item.goods_quantity,
                          0
                        )}
                      </div>
                    </div>

                    <div
                      className="col m-2 p-2 rounded border"
                      style={{ background: "rgba(0, 167, 156, 0.235)" }}
                    >
                      <span className="text-secondary">Discount</span>
                      <div className="h4 pt-2">
                        {orderDetails.order_discount}
                      </div>
                    </div>
                  </div>
                  <GoodOrderTable
                    orderGoods={orderGoods}
                    orderDetails={orderDetails}
                  />
                  <div
                    className="p-3 m-2 rounded bg-light"
                    style={{ border: "1px solid rgba(0, 0, 0, 0.1)" }}
                  >
                    <div>Currently no any descriptions for this order</div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
        <ToastContainer position="top-right" />
      </div>
    </>
  );
}

export default OrderView;
