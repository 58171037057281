import React from "react";
import { useNavigate } from "react-router-dom";
import { Outlet, Link } from "react-router-dom";
import Icon from "@mdi/react";
import {
  mdiViewDashboard,
  mdiShopping,
  mdiBookmarkCheck,
  mdiStore,
  mdiSale,
  mdiAccountCog,
} from "@mdi/js";
import { useSelector } from "react-redux";
import * as mdiIcons from "@mdi/js";
import SidePanelProfile from "./SidePanelProfile";

function SidePanel() {
  const userData = useSelector((state) => state.authorization.userData);

  const NavItem = ({ to, title, icon }) => {
    const iconPath = mdiIcons[icon];
    return (
      <li className="nav-item">
        <Link className="nav-link" to={to}>
          <span className="menu-title">{title}</span>
          <i className="menu-icon">
            <Icon path={iconPath} size={1} />
          </i>
        </Link>
      </li>
    );
  };

  return (
    <>
      <nav
        className="col sidebar sidebar-offcanvas position-fixed"
        id="sidebar"
      >
        <ul className="nav">
          <SidePanelProfile />
          <NavItem to="/" title="Dashboard" icon="mdiViewDashboard" />
          <NavItem to="/stock" title="Stock" icon="mdiShopping" />
          <NavItem to="/orders" title="Orders" icon="mdiBookmarkCheck" />
          <NavItem to="/stores" title="Stores" icon="mdiStore" />
          <NavItem to="/staffs" title="Staffs" icon="mdiAccount" />
          <NavItem to="/profile" title="Profile" icon="mdiAccountCog" />
        </ul>
      </nav>
      <Outlet />
    </>
  );
}

export default SidePanel;
