import axiosInstance from "../../../../app/axiosInstance";
import imageCompression from "browser-image-compression";

export const apiSettings = {
  goodsFetch: async (token) => {
    const response = await axiosInstance
      .get(`/stock/goods/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        return err.response;
      });
    return response;
  },
  goodsTypeFetch: async (token) => {
    const response = await axiosInstance
      .get("/stock/goods_type/", {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        return err.response;
      });
    return response;
  },

  postItem: async (data) => {
    let ItemForm = new FormData();
    const token = data?.token;
    const state = data?.state;

    const imagePreparation = (percent) => {
      //console.log(`onProgress ${percent}`);
    };

    if (data?.images && data?.images?.length > 0) {
      for (let index = 0; index < data?.images?.length; index++) {
        const imageFile = data?.images[index].file;
        const options = {
          maxSize: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
          onprogress: imageFile,
        };
        const compressedFile = await imageCompression(imageFile, options);

        if (index === 0) {
          ItemForm.append(
            `goods_image`,
            compressedFile,
            data?.images[index]?.file.name
          );
        } else {
          ItemForm.append(
            `goods_image${index + 1}`,
            compressedFile,
            data?.images[index]?.file.name
          );
        }
      }
    }

    let postData;
    if (state) {
      ItemForm.append("store", data.store);
      ItemForm.append("goods_type", data.goods_type);
      ItemForm.append("goods_name", data.goods_name);
      ItemForm.append("goods_number", data.goods_number);
      ItemForm.append("lower_price_margin", data.lower_price_margin);
    } else {
      ItemForm.append("goods_type", data.goodstype);
      ItemForm.append("store", data.store);
      ItemForm.append("goods_name", data.goodsName);
      ItemForm.append("goods_number", data.goodsNumber);
      ItemForm.append("goods_quantity", data.quantityAdded);
      ItemForm.append("buying_price", data.buyingPrice);
      ItemForm.append("lower_price_margin", data.sellingPrice);
      ItemForm.append("goods_notes", data.goods_notes);
      ItemForm.append("restricted_price", data.restricted_price);
    }

    postData = [ItemForm];

    const postResponse = await axiosInstance
      .post("/stock/goods/", ItemForm, {
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        return err.response;
      });

    const putResponse = await axiosInstance
      .put(`/stock/goods/${state?.id}/`, ItemForm, {
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        return err.response;
      });

    if (state) {
      return putResponse;
    }

    return postResponse;
  },
};

export default apiSettings;
