import React from "react";

function colors() {
  const COLORS = [
    "#0088FE", // Blue
    "#00C49F", // Green
    "#FFBB28", // Yellow
    "#FF8042", // Orange
    "#e60228", // Red
    "#8e12b0", // Purple
    "#38072a", // Dark Purple
    "#FF4500", // Orange Red
    "#4B0082", // Indigo
    "#9370DB", // Medium Purple
    "#FFD700", // Gold
    "#32CD32", // Lime Green
    "#8A2BE2", // Blue Violet
    "#00FF00", // Lime
    "#FF1493", // Deep Pink
    "#808000", // Olive
    "#20B2AA", // Light Sea Green
    "#40E0D0", // Turquoise
    "#4682B4", // Steel Blue
    "#556B2F", // Dark Olive Green
    "#8B008B", // Dark Magenta
    "#FF69B4", // Hot Pink
    "#4B0082", // Indigo
    "#87CEEB", // Sky Blue
    "#2F4F4F", // Dark Slate Gray
    "#00FA9A", // Medium Spring Green
    "#00CED1", // Dark Turquoise
    "#F0E68C", // Khaki
    "#800000", // Maroon
    "#8FBC8F", // Dark Sea Green
    "#9932CC", // Dark Orchid
    "#FF6347", // Tomato
    "#CD5C5C", // Indian Red
    "#778899", // Light Slate Gray
    "#7CFC00", // Lawn Green
    "#FF7F50", // Coral
    "#B0C4DE", // Light Steel Blue
    "#00BFFF", // Deep Sky Blue
    "#00FFFF", // Aqua
    "#FFA07A", // Light Salmon
    "#FF00FF", // Magenta
    "#DAA520", // Goldenrod
    "#FF4500", // Orange Red
    "#9400D3", // Dark Violet
    "#D2691E", // Chocolate
    "#A52A2A", // Brown
    "#6495ED", // Cornflower Blue
    "#5F9EA0", // Cadet Blue
    "#9932CC", // Dark Orchid
    "#FF8C00", // Dark Orange
    "#DA70D6", // Orchid
    "#7B68EE", // Medium Slate Blue
    "#FFE4B5", // Moccasin
    "#FF00FF", // Fuchsia
    "#7FFF00", // Chartreuse
    "#8B0000", // Dark Red
    "#48D1CC", // Medium Turquoise
    "#D8BFD8", // Thistle
    "#800080", // Purple
    "#1E90FF", // Dodger Blue
    "#7FFFD4", // Aquamarine
    "#F08080", // Light Coral
    "#2E8B57", // Sea Green
    "#FF6347", // Tomato
    "#FFDAB9", // Peachpuff
    "#696969", // Dim Gray
    "#B22222", // Firebrick
    "#20B2AA", // Light Sea Green
    "#6495ED", // Cornflower Blue
    "#E9967A", // Dark Salmon
    "#9370DB", // Medium Purple
    "#40E0D0", // Turquoise
    "#DC143C", // Crimson
    "#B0E0E6", // Powder Blue
    "#7B68EE", // Medium Slate Blue
    "#2F4F4F", // Dark Slate Gray
    "#800000", // Maroon
    "#B0C4DE", // Light Steel Blue
    "#4169E1", // Royal Blue
    "#32CD32", // Lime Green
    "#778899", // Light Slate Gray
    "#808000", // Olive
    "#FA8072", // Salmon
    "#8A2BE2", // Blue Violet
    "#00FF7F", // Spring Green
    "#8B4513", // Saddle Brown
    "#9370DB", // Medium Purple
    "#FA8072", // Salmon
    "#DDA0DD", // Plum
    "#228B22", // Forest Green
    "#6B8E23", // Olive Drab
    "#4682B4", // Steel Blue
    "#D2B48C", // Tan
    "#00FF00", // Lime
    "#8B0000", // Dark Red
    "#00BFFF", // Deep Sky Blue
    "#FF8C00", // Dark Orange
    "#556B2F", // Dark Olive Green
    "#DB7093", // Pale Violet Red
    "#7FFF00", // Chartreuse
    "#F4A460", // Sandy Brown
    "#2E8B57", // Sea Green
    "#A52A2A", // Brown
    "#FF4500", // Orange Red
    "#FFA500", // Orange
    "#8B008B", // Dark Magenta
    "#483D8B", // Dark Slate Blue
    "#FF6347", // Tomato
    "#800080", // Purple
    "#778899", // Light Slate Gray
    "#FFD700", // Gold
    "#808000", // Olive
    "#7CFC00", // Lawn Green
    "#4B0082", // Indigo
    "#F0E68C", // Khaki
    "#6A5ACD", // Slate Blue
    "#7B68EE", // Medium Slate Blue
    "#DAA520", // Goldenrod
    "#00FA9A", // Medium Spring Green
    "#DA70D6", // Orchid
    "#FF6347", // Tomato
    "#48D1CC", // Medium Turquoise
    "#40E0D0", // Turquoise
    "#FF00FF", // Magenta
    "#778899", // Light Slate Gray
    "#32CD32", // Lime Green
    "#800000", // Maroon
    "#4169E1", // Royal Blue
    "#2F4F4F", // Dark Slate Gray
  ];

  return COLORS;
}

export default colors;
