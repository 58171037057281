import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  savedGoods: [],
  savedGoodsTypes: [],
  savedGoodsUpdate: [],
  searchWord: [],
};

export const StockSlice = createSlice({
  name: "stockStore",
  initialState,
  reducers: {
    setSavedGoods: (state, action) => {
      state.savedGoods = action.payload;
    },
    setSavedGoodsTypes: (state, action) => {
      state.savedGoodsTypes = action.payload;
    },
    setSavedGoodsUpdate: (state, action) => {
      state.savedGoodsUpdate = action.payload;
    },
    setSearchWord: (state, action) => {
      state.searchWord = action.payload;
    },
  },
});

export const {
  setSavedGoods,
  setSavedGoodsTypes,
  setSavedGoodsUpdate,
  setSearchWord,
} = StockSlice.actions;

export default StockSlice.reducer;
