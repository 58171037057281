import { error } from "jquery";
import axiosInstance from "../../app/axiosInstance";
import imageCompression from "browser-image-compression";

export const apiSettings = {
  //Company APIs
  StoreFetch: async (token) => {
    const response = await axiosInstance
      .get("/stock/store/", {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        return err;
      });
    return response;
  },

  companyFetch: async (token) => {
    const response = await axiosInstance
      .get("/auth/company/", {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        return err.response;
      });
    return response;
  },

  //users APIs
  registerUser: async (data) => {
    const token = data.token;
    const stableData = data.stableData;
    const response = await axiosInstance
      .post(`/auth/user_registration/`, stableData, {
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        return err.response;
      });
    return response;
  },

  userProfileFetch: async (data) => {
    const token = data.token;
    const userId = data.userId;

    const response = await axiosInstance
      .get(`/auth/user_profile/${userId}/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        return err.response;
      });
    return response;
  },

  //Stock management APIs
  goodsFetch: async (token) => {
    const response = await axiosInstance
      .get(`/stock/goods/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        return err.response;
      });
    return response;
  },

  goodsTypeFetch: async (token) => {
    const response = await axiosInstance
      .get("/stock/goods_type/", {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        return err.response;
      });
    return response;
  },

  postItem: async (data) => {
    let ItemForm = new FormData();
    const token = data?.token;
    const state = data?.state;

    const imagePreparation = (percent) => {
      //console.log(`onProgress ${percent}`);
    };

    if (data?.images && data?.images?.length > 0) {
      for (let index = 0; index < data?.images?.length; index++) {
        const imageFile = data?.images[index].file;
        const options = {
          maxSize: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
          onprogress: imageFile,
        };
        const compressedFile = await imageCompression(imageFile, options);

        if (index === 0) {
          ItemForm.append(
            `goods_image`,
            compressedFile,
            data?.images[index]?.file.name
          );
        } else {
          ItemForm.append(
            `goods_image${index + 1}`,
            compressedFile,
            data?.images[index]?.file.name
          );
        }
      }
    }

    let postData;
    if (state) {
      ItemForm.append("store", data.store);
      ItemForm.append("goods_type", data.goods_type);
      ItemForm.append("goods_name", data.goods_name);
      ItemForm.append("goods_number", data.goods_number);
      ItemForm.append("lower_price_margin", data.lower_price_margin);
    } else {
      ItemForm.append("goods_type", data.goodstype);
      ItemForm.append("store", data.store);
      ItemForm.append("goods_name", data.goodsName);
      ItemForm.append("goods_number", data.goodsNumber);
      ItemForm.append("goods_quantity", data.quantityAdded);
      ItemForm.append("buying_price", data.buyingPrice);
      ItemForm.append("lower_price_margin", data.sellingPrice);
      ItemForm.append("goods_notes", data.goods_notes);
      ItemForm.append("restricted_price", data.restricted_price);
    }

    postData = [ItemForm];

    const postResponse = await axiosInstance
      .post("/stock/goods/", ItemForm, {
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        return err.response;
      });

    const putResponse = await axiosInstance
      .put(`/stock/goods/${state?.id}/`, ItemForm, {
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        return err.response;
      });

    if (state) {
      return putResponse;
    }

    return postResponse;
  },

  deleteItem: async (data) => {
    const itemID = data.itemID;
    const token = data.token;
    const response = await axiosInstance
      .delete(`/stock/temporary_goods_update/${itemID}/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        return err;
      });
    return response;
  },

  approveItem: async (data) => {
    const column1Data = data.column1Data;
    const user = data.user;
    const column3Data = data.column3Data;
    const token = data.token;

    const response = await axiosInstance
      .post(
        `/stock/stock_update/`,
        {
          goods: column1Data,
          updated_by: user.id,
          quantity_added: column3Data,
        },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        return err;
      });
    return response;
  },

  //orders
  createOrder: async (data) => {
    const order = data.order;
    const token = data.token;

    const response = await axiosInstance
      .post("/order/create_order/", order, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        return err?.response;
      });
    return response;
  },

  fetchOrders: async (token) => {
    const response = await axiosInstance
      .get("/order/create_order/", {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        return err;
      });
    return response;
  },

  editOrder: async (data) => {
    const order = data.order;
    const token = data.token;
    const order_no = data.order_no;

    const response = await axiosInstance
      .put(`/order/create_order/${order_no}/`, order, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        return err;
      });
    return response;
  },

  orderData: async (data) => {
    const token = data.token;
    const orderId = data.orderId;

    const response = await axiosInstance
      .get(`/order/create_order/${orderId}/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        return err;
      });
    return response;
  },

  fetchOrderStatus: async (token) => {
    const response = await axiosInstance
      .get("/order/create_order_status/", {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        return err;
      });
    return response;
  },

  orderAuthorization: async (data) => {
    const token = data.token;
    const status = {
      authorization_status: data.actionToServer,
    };

    const response = await axiosInstance
      .put(`/order/create_order/${data.orderId}/`, status, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        return err;
      });
    return response;
  },

  deleteOrder: async (data) => {
    const token = data.token;
    const order = { order_no: data.order_no };

    const response = await axiosInstance
      .post(`/order/delete_order/`, order, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        return err;
      });
    return response;
  },
};

export default apiSettings;
