import React from "react";
import { ToastContainer } from "react-toastify";

function NotifyMsg({ closeToast, Msg, yesMethod }) {
  return (
    <div>
      <div className="fw-bold">{Msg}</div>
      <br />
      <div>
        <button className="btn btn-success me-2" onClick={yesMethod}>
          Ndio
        </button>
        <button onClick={closeToast} className="btn btn-danger me-2">
          Hapana
        </button>
      </div>
    </div>
  );
}

export default NotifyMsg;
