import React, { useState } from "react";
import { createSlice } from "@reduxjs/toolkit";
import env from "react-dotenv";
import CryptoJS from "crypto-js";

let decryptedToken = "";

const key = env.REACT_APP_ENCRYPTION_KEY;
const storedToken = localStorage.getItem("caffein");

if (storedToken) {
  const bytes = CryptoJS.AES.decrypt(storedToken, key);
  decryptedToken = bytes.toString(CryptoJS.enc.Utf8);
}

const initialState = {
  token: decryptedToken,
  userData: [],
  savedStaff: [],
  savedCompay: [],
  isLoading: true,
  filteredPosition: null,
  savedOrders: [],
  savedStores: [],
  statisticalFilter: [],
  savedOrderDetails: [],
  savedDetailChange: [],
  savedDeleteId: 0,
  savedOrderDiscount: 0,
  savedQuantity: 0,
  savedSignalLength: 0,
};

export const AuthSlice = createSlice({
  name: "authorization",
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setUser: (state, action) => {
      state.userData = action.payload;
    },
    setSavedCompany: (state, action) => {
      state.savedCompay = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setFilteredPosition: (state, action) => {
      state.filteredPosition = action.payload;
    },
    setSavedOrders: (state, action) => {
      state.savedOrders = action.payload;
    },
    setSavedGoods: (state, action) => {
      state.savedGoods = action.payload;
    },
    setSavedGoodsTypes: (state, action) => {
      state.savedGoodsTypes = action.payload;
    },
    setSavedStores: (state, action) => {
      state.savedStores = action.payload;
    },
    setSavedGoodsUpdate: (state, action) => {
      state.savedGoodsUpdate = action.payload;
    },
    setStatisticalFilter: (state, action) => {
      state.statisticalFilter = action.payload;
    },
    setSavedDetailChange: (state, action) => {
      state.savedDetailChange = action.payload;
    },
    setSavedDeleteId: (state, action) => {
      state.savedDeleteId = action.payload;
    },
    setSavedOrderDetails: (state, action) => {
      state.savedOrderDetails = action.payload;
    },
    setSavedOrderDiscount: (state, action) => {
      state.savedOrderDiscount = action.payload;
    },
    setSavedQuantity: (state, action) => {
      state.savedQuantity = action.payload;
    },
    setSavedSignalLength: (state, action) => {
      state.savedSignalLength = action.payload;
    },
    setSavedStaff: (state, action) => {
      state.savedStaff = action.payload;
    },
  },
});

export const {
  setToken,
  setUser,
  setIsLoading,
  setFilteredPosition,
  setSavedOrders,
  setSavedGoods,
  setSavedGoodsTypes,
  setSavedStores,
  setSavedGoodsUpdate,
  setStatisticalFilter,
  setSavedDetailChange,
  setSavedDeleteId,
  setSavedOrderDetails,
  setSavedOrderDiscount,
  setSavedCompany,
  setSavedQuantity,
  setSavedSignalLength,
  setSavedStaff,
} = AuthSlice.actions;

export default AuthSlice.reducer;
