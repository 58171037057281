import { mdiPencil } from "@mdi/js/mdi";
import Icon from "@mdi/react";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { setSavedDetailChange } from "../../app/AuthSlice";
import { setSavedGoods } from "../../app/StockSlice";
import axiosInstance from "../../app/axiosInstance";
import { ToastContainer, toast } from "react-toastify";
import ToastNetworkError from "../Components/ToastNetworkError";

function EditGoodsOrder({ values, orderID, amountError }) {
  const token = useSelector((state) => state.authorization.token);
  const savedGoods = useSelector((state) => state.stockStore.savedGoods);
  const savedDetailChange = useSelector(
    (state) => state.authorization.savedDetailChange
  );
  const dispatch = useDispatch();
  const [goods, setGoods] = useState();
  const [itemNumberData, SetItemNumberData] = useState({
    price: 0,
  });
  const [orderGoodChange, setOrderGoodChanges] = useState({
    price: 0,
    goodsQuantity: 0,
    orderNo: orderID ? orderID : 0,
    goods: 0,
  });
  const [errorFields, setErrorField] = useState({
    price: true,
    goodsQuantity: true,
    orderNo: true,
    goods: true,
  });

  //default model
  const [modal, setModal] = useState(false);
  const toggle = () => {
    if (modal) {
      setOrderGoodChanges({
        price: 0,
        goodsQuantity: 0,
        orderNo: orderID ? orderID : 0,
        goods: 0,
      });
      setErrorField({
        price: true,
        goodsQuantity: true,
        orderNo: true,
        goods: true,
      });
    }
    setModal(!modal);
  };

  useEffect(() => {
    SetItemNumberData({ ...itemNumberData, ["price"]: values.price });
    if (savedGoods.length > 0) {
      setGoods(savedGoods);
    }
    const goodsFetch = async () => {
      try {
        const response = await axiosInstance.get("/stock/goods/", {
          headers: {
            Authorization: `Token ${token}`,
          },
        });
        if (savedGoods.length !== response.data.result.length) {
          dispatch(setSavedGoods(response.data.result));
          setGoods(response.data.result);
        }
      } catch (error) {}
    };
    goodsFetch();
  }, [modal]);

  //filtering goods based on clicked item
  let selectedItem;
  if (goods) {
    selectedItem = goods.filter(
      (item) => item.goods_number === values.goods_number
    )[0];
  }

  //handle input changes
  const handleChanges = (e) => {
    const { name, value } = e.target;
    setErrorField({ ...errorFields, [name]: true });
    const filteredValues = value
      .split(" ")
      .filter((item) => item !== "").length;
    let numericValue = value;
    if (filteredValues < 2) {
      numericValue = parseFloat(value.replace(/,/g, ""));
    }
    if (!isNaN(numericValue) || value === "") {
      const displayedData = value === "" ? "" : numericValue.toLocaleString();
      SetItemNumberData({ ...itemNumberData, [name]: displayedData });
      setOrderGoodChanges({
        ...orderGoodChange,
        [name]: isNaN(numericValue) ? "" : numericValue,
      });
    } else {
      setOrderGoodChanges({ ...orderGoodChange, [name]: value });
    }
  };

  //handling form submit

  const changesToGo = { ...orderGoodChange, ["goods"]: selectedItem?.id };

  const handleSubmit = () => {
    //toggle();
    const errorUpdates = { ...errorFields };

    //make sure the added amount does not exceed the available stock
    let difference = changesToGo.goodsQuantity - values.goods_quantity;
    if (difference > selectedItem.goods_available_for_sales) {
      amountError();
      errorUpdates["goodsQuantity"] = false;
      setErrorField(errorUpdates);
    }

    //validation
    const itemsValidation = () => {
      const keys = Object.keys(changesToGo);
      keys.map((item, index) => {
        const itemName = keys[index];

        changesToGo[itemName] !== 0
          ? (errorUpdates[itemName] = true)
          : (errorUpdates[itemName] = false);
      });
      setErrorField(errorUpdates);
      const errors = Object.keys(errorUpdates);
      return errors.some((error) => errorUpdates[error] === false);
    };

    if (!itemsValidation()) {
      const sendData = async () => {
        toast("Sending changes....", { autoClose: true });
        try {
          const response = await axiosInstance.put(
            `/order/update_goods_order/${values.id}/`,
            {
              goods_issue: {
                price: changesToGo.price,
                goods_quantity: changesToGo.goodsQuantity,
                order: changesToGo.orderNo,
                issued_status: false,
              },
            },
            {
              headers: {
                Authorization: `Token ${token}`,
              },
            }
          );
          if (response.status === 200) {
            toast.dismiss();
            toast.success("umefanikiwa kurekebisha bidhaa kikamilifu", {
              autoClose: true,
            });
            dispatch(setSavedDetailChange(response.data));
            toggle();
          }
        } catch (error) {
          ToastNetworkError();
        }
      };
      sendData();
    }
  };

  return (
    <div>
      <div>
        <a
          className="text-decoration-none"
          onClick={toggle}
          style={{ cursor: "pointer" }}
        >
          <i className="text-success p-3">
            <Icon path={mdiPencil} size={1} />
          </i>
        </a>
        <Modal
          isOpen={modal}
          toggle={toggle}
          centered
          modalTransition={{ timeout: 50 }}
          backdropTransition={{ timeout: 100 }}
        >
          <ModalHeader toggle={toggle}>
            <div>Edit Product 1</div>
          </ModalHeader>
          <ModalBody>
            <div className="d-inline-flex gap-3 pb-3 text-secondary">
              <div>
                Available items on stock:{" "}
                <span className="fw-bolder text-dark">
                  {selectedItem && selectedItem.goods_available_for_sales}
                </span>
              </div>
              <div>
                item price:{" "}
                <span className="fw-bolder text-dark">
                  {selectedItem &&
                    selectedItem.lower_price_margin.toLocaleString()}
                </span>
              </div>
            </div>
            <form className="py-3">
              <div className="d-inline-flex gap-2">
                <div className="form-group">
                  <label for="ItemAmount">
                    Changes item Amount
                    {!errorFields.goodsQuantity && (
                      <span className="text-danger">required</span>
                    )}
                  </label>
                  <input
                    type="number"
                    min="0"
                    className="form-control"
                    id="itemAmount"
                    name="goodsQuantity"
                    onChange={handleChanges}
                    placeholder={values.goods_quantity}
                  />
                </div>
                <div className="form-group">
                  <label for="ItemPrice">
                    Change price{" "}
                    {!errorFields.price && (
                      <span className="text-danger">required</span>
                    )}
                  </label>
                  <input
                    type="text"
                    min="0"
                    className="form-control"
                    id="itemPrice"
                    name="price"
                    value={itemNumberData?.price}
                    onChange={handleChanges}
                    placeholder={values.price.toLocaleString()}
                  />
                </div>
              </div>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button color="success" onClick={handleSubmit}>
              Confirm
            </Button>{" "}
            <Button color="secondary" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
}

export default EditGoodsOrder;
