import React, { useEffect, useState } from "react";
import PageHeader from "../Components/pageHeader";
import { mdiAccount } from "@mdi/js";
import Footer from "../Components/footer";
import { useDispatch, useSelector } from "react-redux";
import AxiosInstance from "../../app/axiosInstance";
import ToastNetworkError from "../Components/ToastNetworkError";
import { useNavigate } from "react-router-dom";
import NotifyMsg from "../Components/NotifyMsg";
import { ToastContainer, toast } from "react-toastify";
import { Box, TextField, MenuItem, Tooltip } from "@mui/material";
import { setSavedStores } from "../../app/AuthSlice";
import API from "../../services/api/API";

function AddStaff() {
  const token = useSelector((state) => state.authorization.token);
  const user = useSelector((state) => state.authorization.userData);
  const savedStores = useSelector((state) => state.authorization.savedStores);
  const [stores, setStores] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeStore, setActiveStore] = useState({
    store: [],
  });
  const [registrationData, setRegistrationData] = useState({
    username: "",
    first_name: "",
    last_name: "",
    phone_number: "",
    user_company: "",
    email: "",
    user_groups: [],
    password: "",
  });
  const [errorFields, setErrorField] = useState({
    username: true,
    first_name: true,
    last_name: true,
    phone_number: true,
    user_company: true,
    email: true,
    user_groups: true,
    password: true,
  });

  const [password, setPassword] = useState({
    password1: "",
    password2: "",
  });

  const userGroups = ["storekeeper", "cashier"];

  useEffect(() => {
    setRegistrationData({
      ...registrationData,
      ["user_groups"]: "storekeeper",
    });
    //fetching for store if unavailable
    if (savedStores.length > 0) {
      setStores(savedStores);
      setIsLoading(false);
    } else {
      const storeFetch = async () => {
        try {
          const response = await API.StoreFetch(token);
          setStores(response.data.result);
          dispatch(setSavedStores(response.data.result));
          setIsLoading(false);
        } catch (error) {
          ToastNetworkError();
        }
      };
      storeFetch();
    }
  }, []);

  //handleitemChange
  function validateEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  const validatePassword = (pass) => {
    if (pass.length < 8) {
      return false;
    }

    // Check for complexity
    const complexityRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/;
    if (!complexityRegex.test(pass)) {
      return false;
    }

    // Check against common patterns
    const commonPatternsRegex =
      /^(?=.*[a-zA-Z])(?=.*\d)(?!.*[\s])(?=.*[!@#$%^&*()_+}{":;'?/>.<,])(?!.*(.)\1\1)[a-zA-Z0-9!@#$%^&*()_+}{":;'?/>.<,]{8,}$/;
    if (!commonPatternsRegex.test(pass)) {
      return false;
    }
    return true;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "email") {
      value !== "" && setErrorField({ ...errorFields, [name]: true });
      if (validateEmail(value)) {
        setRegistrationData({ ...registrationData, [name]: value });
      }
    } else if (name === "phone_number") {
      value !== "" && setErrorField({ ...errorFields, [name]: true });
      const phone = parseFloat(value);
      if (!isNaN(phone) && value.length === 10) {
        setRegistrationData({ ...registrationData, [name]: value });
      } else {
        setRegistrationData({ ...registrationData, [name]: "" });
      }
    } else if (name === "password1" || name === "password2") {
      value !== "" && setErrorField({ ...errorFields, ["password"]: true });
      if (validatePassword(value) && name === "password1") {
        setPassword({ ...password, password1: value });
      }
      if (validatePassword(value) && name === "password2") {
        const password1 = password.password1;
        setPassword({ ...password, password2: value });
        password1 === value &&
          setRegistrationData({ ...registrationData, password: value });
      }
    } else {
      setRegistrationData({ ...registrationData, [name]: value });
      value !== "" && setErrorField({ ...errorFields, [name]: true });
    }
  };

  const formSubmiting = (e) => {
    e.preventDefault();
    const stableData = { ...registrationData };
    stableData["user_company"] = user.user_company;
    setRegistrationData({
      ...registrationData,
      ["user_company"]: user.user_company,
    });

    //validating for empty fields, return true if there is empty field
    const itemsValidation = () => {
      const keys = Object.keys(stableData);
      const errorUpdates = { ...errorFields };
      keys.map((item, index) => {
        const itemName = keys[index];

        stableData[itemName] !== ""
          ? (errorUpdates[itemName] = true)
          : (errorUpdates[itemName] = false);
      });

      setErrorField(errorUpdates);

      const errors = Object.keys(errorUpdates);
      return errors.some((error) => errorUpdates[error] === false);
    };

    const error = itemsValidation();

    //sending Data to the API after completing validation
    const userRegistrationPost = async () => {
      try {
        const response = await API.registerUser({ token, stableData });
        if (response.status === 201) {
          navigate("/staffs", { state: { addedStaff: true } });
        }
      } catch (error) {
        toast.dismiss();
        if (error.response.status === 400) {
          toast.error(
            `Mtumiaji mwenye username ${stableData.username} Tayari anapatikana katika mfumo. Jaribu username nyingine`
          );
          setErrorField({ ...errorFields, ["username"]: false });
        } else {
          ToastNetworkError();
        }
      }
    };

    !error &&
      toast(
        <NotifyMsg
          Msg={"Umekagua Taarifa hizi na uko tayari kumsajiri staff mpya?"}
          yesMethod={userRegistrationPost}
        />,
        { autoClose: false }
      );
  };

  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <PageHeader
          PageName="Staffs"
          PageIcon={mdiAccount}
          requireBack={true}
        />
        <div>
          <div className="mt-3">
            <div className="h4 p-2 text-secondary">Add Staff</div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <form className="forms-sample">
                  <div className="row flex-row">
                    <div className="col-md-6">
                      <div className="form-group d-inline-flex gap-2 w-100">
                        <div className="form-control p-0 border-0">
                          <Box
                            component="form"
                            noValidate
                            autoComplete="on"
                            required
                          >
                            <TextField
                              id="First"
                              label="First name"
                              name="first_name"
                              fullWidth
                              variant="outlined"
                              onChange={handleChange}
                            />
                          </Box>
                        </div>
                        <div className="form-control p-0 border-0">
                          <Box
                            component="form"
                            noValidate
                            autoComplete="on"
                            required
                          >
                            <TextField
                              id="Last"
                              label="Last name"
                              name="last_name"
                              fullWidth
                              variant="outlined"
                              onChange={handleChange}
                            />
                          </Box>
                        </div>
                      </div>
                      <div className="form-group d-inline-flex gap-2 w-100">
                        <div className="form-control p-0 border-0">
                          <Box
                            component="form"
                            noValidate
                            autoComplete="on"
                            required
                          >
                            <TextField
                              id="Phone"
                              label="Phone number"
                              name="phone_number"
                              fullWidth
                              variant="outlined"
                              onChange={handleChange}
                            />
                          </Box>
                        </div>
                        <div className="form-control p-0 border-0">
                          <Box
                            component="form"
                            noValidate
                            autoComplete="on"
                            required
                          >
                            <TextField
                              id="Email"
                              label="Email"
                              name="email"
                              fullWidth
                              variant="outlined"
                              onChange={handleChange}
                            />
                          </Box>
                        </div>
                      </div>
                      <div className="form-group d-inline-flex gap-2 w-100">
                        <div className="form-control p-0 border-0">
                          <Box
                            component="form"
                            noValidate
                            autoComplete="on"
                            required
                          >
                            <div>
                              <TextField
                                id="Shops"
                                fullWidth
                                label="Shops"
                                name="store"
                                variant="outlined"
                                select
                                onChange={handleChange}
                              >
                                {stores.map((store) => (
                                  <MenuItem
                                    key={store.id}
                                    value={store.store_name}
                                  >
                                    {store?.store_name}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </div>
                          </Box>
                        </div>
                        <div className="form-control p-0 border-0">
                          <Box
                            component="form"
                            noValidate
                            autoComplete="on"
                            required
                          >
                            <div>
                              <TextField
                                id="Position"
                                fullWidth
                                label="Position"
                                name="user_groups"
                                variant="outlined"
                                select
                                onChange={handleChange}
                              >
                                {userGroups.map((group, index) => (
                                  <MenuItem key={index} value={group}>
                                    {group}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </div>
                          </Box>
                        </div>
                      </div>
                      <div className="form-group">
                        <Box
                          component="form"
                          noValidate
                          autoComplete="on"
                          required
                        >
                          <TextField
                            id="Username"
                            label="User Name"
                            name="username"
                            fullWidth
                            variant="outlined"
                            onChange={handleChange}
                          />
                        </Box>
                      </div>
                      <div className="form-group">
                        <Box
                          component="form"
                          noValidate
                          autoComplete="on"
                          required
                        >
                          <TextField
                            id="Password"
                            label="Password"
                            name="password1"
                            fullWidth
                            variant="outlined"
                            onChange={handleChange}
                          />
                        </Box>
                        <span
                          className="text-secondary"
                          style={{ fontSize: "0.8rem" }}
                        >
                          Lazima iwe na herufi 8 au zaidi, ikiwemo herufi kubwa,
                          namba na alama
                        </span>
                      </div>

                      <div className="form-group">
                        <Box
                          component="form"
                          noValidate
                          autoComplete="on"
                          required
                        >
                          <TextField
                            id="ConfirmPassword"
                            label="Confirm Password"
                            name="password2"
                            fullWidth
                            variant="outlined"
                            onChange={handleChange}
                          />
                        </Box>
                      </div>
                    </div>

                    <div className="col-md-6 d-flex justify-content-center">
                      <div className="form-group">
                        <Tooltip title="Weka picha ya mfanyakazi">
                          <div className="btn btn-color">
                            <input
                              type="file"
                              className="d-none"
                              id="fileInput"
                              alt="staff-image"
                            />
                            <label htmlFor="fileInput">
                              <div
                                style={{
                                  position: "relative",
                                  display: "inline-block",
                                  cursor: "pointer",
                                }}
                              >
                                <img
                                  src={require("../images/faces/AvatarIcon.jpg")}
                                  alt="Selected File"
                                  id="fileImage"
                                  style={{
                                    height: "25rem",
                                    objectFit: "cover",
                                    objectPosition: "top",
                                  }}
                                  className="rounded w-100"
                                  cursor="pointer"
                                />
                                <div
                                  className="rounded d-flex justify-content-center align-items-center"
                                  style={{
                                    position: "absolute",
                                    top: "0",
                                    left: "0",
                                    height: "100%",
                                    width: "100%",
                                    background: "rgba(0,0,0,0.20)",
                                  }}
                                >
                                  <span className="h4 text-white">
                                    add image
                                  </span>
                                </div>
                              </div>
                            </label>
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center text-center">
                    <Tooltip title="Ongeza mfanyakazi">
                      <button
                        className="btn btn-success"
                        onClick={formSubmiting}
                      >
                        save staff
                      </button>
                    </Tooltip>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <ToastContainer position="top-right" />
    </div>
  );
}

export default AddStaff;
