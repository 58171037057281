import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  BarChart,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Legend,
  Tooltip,
} from "recharts";
import timeFilter from "./timeFilter";
import { element } from "prop-types";

function CustomBarChart() {
  const position = useSelector(
    (state) => state.authorization.statisticalFilter
  );
  const savedOrders = useSelector((state) => state.authorization.savedOrders);
  const [filteredData, setFilteredData] = useState();
  const weekDays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const [data, setData] = useState([
    {
      name: "Monday",
      value: 15,
    },
    {
      name: "Tuesday",
      value: 43,
    },
    {
      name: "Wednesday",
      value: 23,
    },
    {
      name: "Thursday",
      value: 67,
    },
    {
      name: "Friday",
      value: 21,
    },
    {
      name: "Saturday",
      value: 80,
    },
    {
      name: "Sunday",
      value: 10,
    },
  ]);

  useEffect(() => {
    if (savedOrders) {
      const filteredSales = savedOrders.filter(
        (item) => item.authorization_status === "paid"
      );
      setFilteredData(filteredSales);
    }
  }, [savedOrders]);

  // Function to convert date string to day of the week
  const getDayOfWeek = (dateString) => {
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const date = new Date(dateString);
    return days[date.getDay()];
  };

  //order count by day
  const orderCountByDay = {};

  //iterate over API response data
  if (filteredData) {
    const WeeklyOrders = timeFilter({
      filteredTime: "Weekly",
      data: filteredData,
    });
    WeeklyOrders.forEach((element) => {
      const dayOfTheWeek = getDayOfWeek(element.date_added);

      // Increment the count for the corresponding day of the week
      if (orderCountByDay[dayOfTheWeek]) {
        orderCountByDay[dayOfTheWeek]++;
      } else {
        orderCountByDay[dayOfTheWeek] = 1;
      }
    });

    //filling the remaining days of the week with zero if not in the list
    weekDays.forEach((element) => {
      if (!orderCountByDay[element]) {
        orderCountByDay[element] = 0;
      }
    });
  }

  // Convert the order count by day object to the required structure
  const barChartData = Object.entries(orderCountByDay).map(([name, value]) => ({
    name,
    value,
  }));

  // Function to convert day name to corresponding index
  const dayIndex = (dayName) => {
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    return days.indexOf(dayName);
  };

  // Sort barChartData based on day of the week
  barChartData.sort((a, b) => dayIndex(a.name) - dayIndex(b.name));

  return (
    <ResponsiveContainer width="100%" height={350}>
      <BarChart
        data={barChartData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray={"3 3"} />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="value" fill="#8884d8" />
      </BarChart>
    </ResponsiveContainer>
  );
}

export default CustomBarChart;
