import React, { useEffect, useState } from "react";
import Icon from "@mdi/react";
import { mdiSale } from "@mdi/js";
import {
  mdiBookmarkCheck,
  mdiClipboardCheck,
  mdiDelete,
  mdiEye,
  mdiMenuDown,
  mdiViewDashboard,
} from "@mdi/js";
import CustomLoader from "../CustomLoader";
import { useSelector } from "react-redux";
import { Skeleton } from "@mui/material";

function StatisticTiles({ tilesFor, tilesData, isLoading }) {
  const [backGroundClr, setBackGroundClr] = useState("");
  const [iconPath, setIconPath] = useState();
  const position = useSelector((item) => item.authorization.statisticalFilter);

  useEffect(() => {
    if (tilesFor === "Sales") {
      setBackGroundClr("rgba(5,128,101,0.44)");
      setIconPath(mdiSale);
    } else if (tilesFor === "Orders") {
      setBackGroundClr("rgba(2,106,154,0.50)");
      setIconPath(mdiBookmarkCheck);
    } else if (tilesFor === "Items") {
      setBackGroundClr("rgba(255,56,59,0.50)");
      setIconPath(mdiClipboardCheck);
    }
  }, []);

  return (
    <div className="col-md-4 stretch-card grid-margin mb-2">
      <div
        className="card card-img-holder text-white"
        style={{ background: backGroundClr }}
      >
        <div className="card-body">
          <div
            className="card-img-absolute w-25 h-25 d-flex justify-content-center align-items-center rounded-2"
            style={{ background: "rgba(255,255,255,0.30)" }}
          >
            <div>
              <i className="w-100 h-100" style={{ fontSize: "1.5rem" }}>
                <Icon path={iconPath} size={1} />
              </i>
            </div>
          </div>
          <h4 className="font-weight-normal mb-2">
            {position} {tilesFor === "Items" ? `Sold ${tilesFor}` : tilesFor}
          </h4>
          {isLoading ? (
            <div className="my-3 py-2">
              <Skeleton variant="rectangular" animation="wave" height={40} />
            </div>
          ) : (
            <>
              <h2 className="mb-5">{tilesData.toLocaleString()}</h2>
              {/*
              <h6 className="card-text">Increased by 60%</h6>
              */}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default StatisticTiles;
