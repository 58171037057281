import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { setSavedQuantity } from "../../app/AuthSlice";
import axiosInstance from "../../app/axiosInstance";
import { toast } from "react-toastify";
import ToastNetworkError from "../Components/ToastNetworkError";

function UpdateStockQuantity({ quantity, itemId }) {
  const token = useSelector((state) => state.authorization.token);
  const user = useSelector((state) => state.authorization.userData);
  const savedQuantity = useSelector(
    (state) => state.authorization.savedQuantity
  );
  const dispatch = useDispatch();
  const [errorFields, setErrorField] = useState({
    quantityAdded: true,
  });
  const [quantityAdded, setQuantityAdded] = useState({
    quantity: 0,
  });
  const [itemNumberData, SetItemNumberData] = useState({
    quantityAdded: 0,
  });
  //default model
  const [modal, setModal] = useState(false);
  const toggle = () => {
    if (modal) {
      setQuantityAdded({ ...quantityAdded, ["quantity"]: 0 });
      setErrorField({ ...errorFields, ["quantityAdded"]: true });
      SetItemNumberData({ ...itemNumberData, ["quantityAdded"]: 0 });
    }
    setModal(!modal);
  };

  const handleChanges = (e) => {
    const { name, value } = e.target;
    setErrorField({ ...errorFields, ["quantityAdded"]: true });
    const filteredValues = value
      .split(" ")
      .filter((item) => item !== "").length;
    let numericValue = value;
    if (filteredValues < 2) {
      numericValue = parseFloat(value.replace(/,/g, ""));
    }
    if (!isNaN(numericValue) || value === "") {
      const displayedData = value === "" ? "" : numericValue.toLocaleString();
      SetItemNumberData({ ...itemNumberData, [name]: displayedData });
      setQuantityAdded({
        ...quantityAdded,
        ["quantity"]: isNaN(numericValue) ? 0 : numericValue,
      });
    }
  };

  const handleSubmit = () => {
    //validation
    const errorUpdates = { ...errorFields };
    const itemsValidation = () => {
      const keys = Object.keys(quantityAdded);
      keys.map((item, index) => {
        const itemName = keys[index];

        quantityAdded[itemName] !== 0
          ? (errorUpdates["quantityAdded"] = true)
          : (errorUpdates["quantityAdded"] = false);
      });
      setErrorField(errorUpdates);
      const errors = Object.keys(errorUpdates);
      return errors.some((error) => errorUpdates[error] === false);
    };

    if (!itemsValidation()) {
      const updateGoodFetch = async () => {
        toast("bidhaa zinatumwa, tafadhali subiri...", { autoClose: true });
        try {
          const response = await axiosInstance.post(
            "/stock/stock_update/",
            {
              goods: itemId,
              updated_by: user.id,
              quantity_added: quantityAdded.quantity,
            },
            {
              headers: {
                Authorization: `Token ${token}`,
              },
            }
          );
          if (response.status === 201) {
            toast.dismiss();
            toast.success("umefanikiwa kuongeza idadi ya bidhaa kikamilifu", {
              position: "top-right",
              autoClose: true,
            });
            dispatch(setSavedQuantity(savedQuantity + quantityAdded.quantity));
            toggle();
          }
        } catch (error) {
          toast.dismiss();
          ToastNetworkError();
        }
      };
      updateGoodFetch();
    }
  };

  return (
    <div>
      <div>
        <a
          className="btn btn-success text-decoration-none"
          onClick={toggle}
          style={{ cursor: "pointer" }}
        >
          Update Quantity
        </a>
        <Modal
          isOpen={modal}
          toggle={toggle}
          centered
          modalTransition={{ timeout: 50 }}
          backdropTransition={{ timeout: 100 }}
        >
          <ModalHeader toggle={toggle}>
            <div>update item Quantity</div>
          </ModalHeader>
          <ModalBody>
            <div className="d-inline-flex gap-3 pb-3 text-secondary">
              <div>
                Available items on stock:{" "}
                <span className="fw-bolder text-dark">{quantity}</span>
              </div>
            </div>
            <div className="fw-bold text-dark">
              Note: When you confirm, changes will be sent instantly
            </div>
            <form className="py-3">
              <div className="d-inline-flex gap-2">
                <div className="form-group">
                  <label for="ItemAmount">
                    Quantity added
                    {!errorFields.quantityAdded && (
                      <span className="text-danger">required</span>
                    )}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="itemAmount"
                    name="quantityAdded"
                    onChange={handleChanges}
                    value={itemNumberData.quantityAdded}
                  />
                </div>
              </div>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button color="success" onClick={handleSubmit}>
              Confirm
            </Button>{" "}
            <Button color="secondary" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
}

export default UpdateStockQuantity;
