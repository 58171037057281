import React from "react";

function timeFilter({ filteredTime, data }) {
  const options = {
    weekday: "long",
    day: "numeric",
    month: "long",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  const today = new Date();
  let boundarydate;
  if (filteredTime === "Today") {
    const dayData = data.filter((item) => {
      const itemDate = new Date(item.date_added);
      return (
        itemDate.toLocaleDateString("en-US").split(",")[0] ===
        today.toLocaleDateString("en-US").split(",")[0]
      );
    });
    return dayData;
  } else if (filteredTime === "Weekly") {
    const firstDayOfTheWeek = new Date(today);
    firstDayOfTheWeek.setDate(firstDayOfTheWeek.getDate() - today.getDay());
    firstDayOfTheWeek.setHours(0, 0, 0, 0);
    boundarydate = firstDayOfTheWeek;
  } else if (filteredTime === "Monthly") {
    const firstDayOfTheMonth = new Date(
      today.getFullYear(),
      today.getMonth(),
      1
    );
    firstDayOfTheMonth.setHours(0, 0, 0, 0);
    boundarydate = firstDayOfTheMonth;
  } else {
    return data;
  }

  if (boundarydate) {
    const statisticFilter = data.filter((item) => {
      const itemDate = new Date(item.date_added);
      return itemDate > boundarydate;
    });
    return statisticFilter;
  }
}

export default timeFilter;
