import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import StatisticTiles from "./StatisticTiles";
import { mdiSale } from "@mdi/js";
import {
  mdiBookmarkCheck,
  mdiClipboardCheck,
  mdiDelete,
  mdiEye,
  mdiMenuDown,
  mdiViewDashboard,
} from "@mdi/js";
import Icon from "@mdi/react";
import axiosInstance from "../../../app/axiosInstance";
import Loader from "../loader";
import CustomBarChart from "./CustomBarChart";
import CustomPieChart from "./CustomPieChart";
import { setSavedOrders, setStatisticalFilter } from "../../../app/AuthSlice";
import { ToastContainer, toast } from "react-toastify";
import DropDownFilter from "../dropDownFilter/DropDownFilter";
import timeFilter from "./timeFilter";
import DateConverter from "../DateConverter";

function Statistics() {
  const token = useSelector((state) => state.authorization.token);
  const savedOrders = useSelector((state) => state.order.savedOrders);
  const position = useSelector(
    (state) => state.authorization.statisticalFilter
  );
  const [salesData, setSalesData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const tilesName = ["Sales", "Orders", "Items"];
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setStatisticalFilter("Today"));
    //Returning the saved order data or fetching the Orders data from the API when required
    if (savedOrders.length > 0) {
      setSalesData(savedOrders);
      const filteredSales = savedOrders.filter(
        (item) => item.authorization_status === "paid"
      );
      setFilteredData(filteredSales);
      setIsLoading(false);
    }
    //updating orders when changes occured
    const salesDataFetch = async () => {
      try {
        const response = await axiosInstance.get("/order/create_order/", {
          headers: {
            Authorization: `Token ${token}`,
          },
        });
        if (savedOrders.length !== response.data.results) {
          dispatch(setSavedOrders(response.data.results));
          setSalesData(response.data.results);

          //filtering data to get only the order that have been payed
          if (response.data.results) {
            const filteredSales = response.data.results.filter(
              (item) => item.authorization_status === "paid"
            );
            setFilteredData(filteredSales);
            setIsLoading(false);
          }
        }
      } catch (error) {
        console.log(error); //More error handling is required here
        toast("Kuna tatizo la kimtandao. Jaribu tena", {
          autoClose: true,
        });
      }
    };
    salesDataFetch();
  }, []);

  //methods to return the statistics required
  const getSum = (dataArray, sumOf) => {
    let arr = [];
    if (sumOf === "Sales") {
      dataArray.map(
        (item) =>
          item.total_order_amount?.order_total &&
          arr.push(item.total_order_amount?.order_total)
      );
      const sum = arr.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );
      return sum;
    } else if (sumOf === "Orders") {
      return dataArray.length;
    } else if (sumOf === "Items") {
      dataArray.map((item) =>
        item.order_goods_details.map((good) => arr.push(good.goods_quantity))
      );
      const sum = arr.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );
      return sum;
    }
  };

  //method to return the time margin as required
  let timeMargin = () => {
    const today = new Date();
    if (position === "Today") {
      return "00:00 AM - 11:59 PM";
    }
    if (position === "Weekly") {
      const firstDayOfTheWeek = new Date(today);
      firstDayOfTheWeek.setDate(firstDayOfTheWeek.getDate() - today.getDay());
      firstDayOfTheWeek.setHours(0, 0, 0, 0);
      const start = DateConverter(firstDayOfTheWeek).split("at")[0];
      const end = firstDayOfTheWeek.getDate() + 7;
      return `From ${start}`;
    }
    if (position === "Monthly") {
      const today = new Date();
      const monthName = today.toLocaleString("default", { month: "long" });
      return `${monthName}`;
    }
    if (position === "Yearly") {
      const today = new Date();
      const year = today.toLocaleString("en-US", { year: "numeric" });
      return `${year}`;
    }
  };

  return (
    <>
      <div className="d-inline-flex gap-2 w-100 align-items-center">
        <div>
          <DropDownFilter
            position={position}
            linkName={["Today", "Weekly", "Monthly", "Yearly"]}
            isStatistical={true}
          />
        </div>
        <div className="rounded">
          <button className="btn bg-light text-secondary p-2">
            {timeMargin()}
          </button>
        </div>
      </div>
      <div className="row pt-2">
        {tilesName.map((item) => (
          <StatisticTiles
            isLoading={isLoading}
            tilesFor={item}
            tilesData={getSum(
              timeFilter({ data: filteredData, filteredTime: position }),
              item
            )}
          />
        ))}
      </div>
      <div className="row mt-2">
        <div className="col-lg-4 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Store Capacities</h4>
              <p className="pt-2 text-muted">
                <CustomPieChart />
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-8 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Weekly sales</h4>
              <p className="pt-2 text-muted">
                <CustomBarChart />
              </p>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer position="top-right" />
    </>
  );
}

export default Statistics;
