import React from "react";
import PageHeader from "../Components/pageHeader";
import { mdiAccountCog } from "@mdi/js";
import Footer from "../Components/footer";
import PopUp from "../Components/popUp";
import { useSelector } from "react-redux";

function EditProfile() {
  const token = useSelector((state) => state.authorization.token);

  return (
    <div class="main-panel">
      <div class="content-wrapper">
        <PageHeader
          PageName="Profile"
          PageIcon={mdiAccountCog}
          requireBack={true}
        />
        <div>
          <div>
            <div class="h4 text-secondary">Edit Profile</div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <form class="forms-sample">
                  <div class="row flex-row-reverse">
                    <div class="col-md-6 d-flex justify-content-center">
                      <div class="form-group">
                        <div class="btn btn-color">
                          <input type="file" class="d-none" id="fileInput" />
                          <label for="fileInput">
                            <div
                              style={{
                                position: "relative",
                                display: "inline-block",
                              }}
                            >
                              <img
                                src={require("../images/faces/AvatarIcon.jpg")}
                                alt="Selected File"
                                id="fileImage"
                                style={{
                                  height: "20rem",
                                  width: "20rem",
                                  objectFit: "cover",
                                  objectPosition: "top",
                                }}
                                class="rounded-circle"
                              />
                              <div
                                class="rounded-circle d-flex justify-content-center align-items-center"
                                style={{
                                  position: "absolute",
                                  top: "0",
                                  left: "0",
                                  height: "100%",
                                  width: "100%",
                                  background: "rgba(0,0,0,0.20)",
                                }}
                              >
                                <span class="h4 text-white">Change image</span>
                              </div>
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group d-inline-flex gap-2">
                        <div>
                          <label for="exampleInputName1">First name</label>
                          <input
                            type="text"
                            class="form-control"
                            id="exampleInputName1"
                            placeholder="Weston"
                          />
                        </div>
                        <div>
                          <label for="exampleInputName1">Second name</label>
                          <input
                            type="text"
                            class="form-control"
                            id="exampleInputName1"
                            placeholder="Christopher"
                          />
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="exampleInputNumber">
                          Change phone number
                        </label>
                        <input
                          type="tel"
                          class="form-control"
                          id="exampleInputNumber"
                          placeholder="+255753864413"
                        />
                      </div>
                      <div class="form-group">
                        <label for="exampleInputNumber">Change Email</label>
                        <input
                          type="email"
                          class="form-control"
                          id="exampleInputEmail"
                          placeholder="christonkabeta@gmail.com"
                        />
                      </div>
                      <div class="form-group">
                        <label for="exampleInputPassword4">
                          Change password
                        </label>
                        <input
                          type="password"
                          class="form-control"
                          id="exampleInputPassword4"
                          placeholder="********"
                        />
                      </div>
                      <div class="form-group">
                        <label for="exampleInputPassword4">
                          Confirm password
                        </label>
                        <input
                          type="password"
                          class="form-control"
                          id="exampleInputPassword4"
                          placeholder="********"
                        />
                      </div>
                    </div>
                  </div>
                  <PopUp
                    popUpButtonLabelColor="success"
                    popUpButtonLabel="Tuma mabadiliko"
                    popUpbody="Je upo tayari kutuma mabadiliko?"
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default EditProfile;
