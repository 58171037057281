import React, { useState } from "react";
import { useNavigate } from "react-router";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

function PopUp({
  popUpButtonLabelColor,
  popUpButtonLabel,
  popUpbody,
  good,
  isItemView,
  isProfileView,
  userRegistration,
  yesAction,
}) {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const navigate = useNavigate();

  const yesNavigation = () => {
    if (isItemView) {
      navigate("/item-add", { state: good && good });
    }
    if (isProfileView) {
      if (yesAction) {
        yesAction();
      }
    }
    if (userRegistration) {
      const formSubmiting = (e) => {
        e.preventDefault();
      };
      formSubmiting();
    }
    if (yesAction) {
      yesAction();
      toggle();
    }
  };

  return (
    <div>
      <Button color={popUpButtonLabelColor} onClick={toggle}>
        {popUpButtonLabel}
      </Button>
      <Modal isOpen={modal} toggle={toggle} fade={false} centered>
        <ModalHeader toggle={toggle}></ModalHeader>
        <ModalBody>{popUpbody}</ModalBody>
        <ModalFooter>
          <Button color="success" onClick={yesNavigation}>
            Ndio
          </Button>
          <Button color="secondary" onClick={toggle}>
            Hapana
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default PopUp;
