import Icon from "@mdi/react";
import React, { useEffect, useState } from "react";
import {
  mdiAccountCog,
  mdiEmail,
  mdiInstagram,
  mdiPhone,
  mdiTwitter,
  mdiWhatsapp,
} from "@mdi/js";
import Footer from "../Components/footer";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import PageHeader from "../Components/pageHeader";
import PopUp from "../Components/popUp";
import axiosInstance from "../../app/axiosInstance";
import Loader from "../Components/loader";
import ToastNetworkError from "../Components/ToastNetworkError";
import { toast } from "react-toastify";
import { setSavedStores } from "../../app/AuthSlice";
import CustomLoader from "../Components/CustomLoader";
import { Tooltip } from "@mui/material";
import API from "../../services/api/API";

function StaffView() {
  const user = useSelector((state) => state.authorization.userData);
  const token = useSelector((state) => state.authorization.token);
  const stores = useSelector((state) => state.authorization.savedStores);
  const { state } = useLocation();
  const [userData, setUserData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [company, setCompany] = useState([]);
  const [store, setStores] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    let userId;
    if (state) {
      userId = state.userId;
    } else {
      if (Object.keys(user).length > 0) {
        userId = user.id;
        setUserData(user);
        setIsLoading(false);
      }
    }

    const userDataFetch = async () => {
      try {
        const response = await API.userProfileFetch({ token, userId });
        setUserData(response.data);
        setIsLoading(false);
      } catch (error) {
        ToastNetworkError();
      }
    };
    userDataFetch();

    const companyFetch = async () => {
      try {
        const response = await API.companyFetch(token);
        setCompany(response.data.serialized_data);
      } catch (error) {
        toast.error("Kuna tatizo la kimtandao", { autoclose: true });
      }
    };
    companyFetch();

    if (stores.length > 0) {
      setStores(stores);
    }

    const storeFetch = async () => {
      try {
        const response = await API.StoreFetch(token);
        setStores(response.data.result);
        if (stores.length !== response.data.result.length) {
          dispatch(setSavedStores(response.data.result));
        }
      } catch (error) {
        ToastNetworkError();
      }
    };
    storeFetch();
  }, []);

  //filtering company to return only the related data
  let filteredCompany;
  if (company.length > 0) {
    const filtered = company.filter(
      (comp) => comp.company_name === user.user_company
    );
    filteredCompany = filtered[0];
  }

  //redirecting to change password page
  const goToChangePassword = () => {
    navigate("/change-password", { state: userData });
  };

  return isLoading ? (
    <Loader />
  ) : (
    <div className="main-panel">
      <div className="content-wrapper">
        <PageHeader PageName="Profile" PageIcon={mdiAccountCog} />
        <div className="">
          <div className="h4 text-secondary">Profile details</div>
        </div>
        <div className="row overflow-auto d-flex flex-row-reverse">
          <div className="col-md-6">
            <div className="bg-white rounded d-flex justify-content-center align-items-center">
              <img
                alt="Profile"
                src={require("../images/faces/AvatarIcon.jpg")}
                className="rounded w-100"
                style={{
                  height: "26rem",
                  objectFit: "cover",
                  objectPosition: "center",
                }}
              />
            </div>
          </div>
          <div className="col-md-6 px-3">
            <div className="bg-white rounded px-3">
              <div className="p-2">
                <div className="h4 mt-3 text-secondary fw-bolder">
                  {userData.first_name} {userData.last_name}
                </div>
                <div className="">
                  <p>{userData.username}</p>
                </div>
                <div className="h4 text-success">{userData.groups[0]}</div>
                <div className="pb-2">
                  <Tooltip title={userData.phone_number}>
                    <a role="button">
                      <i
                        className="p-2 text-secondary"
                        style={{ fontSize: "20px" }}
                      >
                        <Icon path={mdiPhone} size={1} />
                      </i>
                    </a>
                  </Tooltip>
                  <Tooltip title={userData.email}>
                    <a role="button">
                      <i
                        className="p-2 text-secondary"
                        style={{ fontSize: "20px" }}
                      >
                        <Icon path={mdiEmail} size={1} />
                      </i>
                    </a>
                  </Tooltip>
                </div>
              </div>
            </div>
            <div className="bg-white rounded p-3 mt-3">
              <div className="h4 text-secondary px-3 fw-bolder">
                Company info
              </div>
              <div className="mt-3">
                <table className="table table-borderless">
                  <tr className="text-start ">
                    <td className="">Company Name</td>
                    <td className=" text-uppercase fw-bold">
                      {filteredCompany?.company_name}
                    </td>
                  </tr>
                  <tr className="text-start ">
                    <td className="">TIN #</td>
                    <td className=" text-uppercase fw-bold">
                      {filteredCompany?.company_tin}
                    </td>
                  </tr>
                  <tr className="text-start ">
                    <td className="">Subscription</td>
                    <td className=" text-uppercase fw-bold">
                      {filteredCompany?.subscriptions}
                    </td>
                  </tr>
                  <tr className="text-start">
                    <td>Branches</td>
                    <td
                      className="fw-bold text-truncate"
                      style={{ maxWidth: "150px" }}
                    >
                      {store ? (
                        store.map((item) => `${item.store_name}, `)
                      ) : (
                        <CustomLoader size={20} />
                      )}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <div className="p-3 my-2 d-inline-flex gap-2 justify-content-center">
                <div>
                  <PopUp
                    popUpButtonLabel="Edit Profile"
                    popUpButtonLabelColor="success"
                    popUpbody="Are you sure you want to edit profile?"
                    isProfileView={true}
                  />
                </div>
                <div>
                  <PopUp
                    popUpButtonLabel="Change password"
                    popUpButtonLabelColor="success"
                    popUpbody="Are you sure you want to change password?"
                    isProfileView={true}
                    yesAction={goToChangePassword}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default StaffView;
