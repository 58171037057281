import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import React from "react";

function AddedImageTile({ imagePath, action }) {
  const imageSrc = imagePath ? imagePath : require("../images/faces/male.jpg");
  return (
    <div className="col-3">
      <div className="position-relative shadow">
        {" "}
        <img
          alt=""
          src={imageSrc}
          style={{ width: "100%", height: "6rem", objectFit: "cover" }}
        />
        <div
          className="position-absolute"
          style={{ top: "0.2rem", right: "0.2rem" }}
        >
          <button
            type="button"
            className="btn bg-light p-0 text-danger shadow"
            onClick={() => action()}
          >
            <Icon path={mdiClose} size={0.8} />
          </button>
        </div>
      </div>
    </div>
  );
}

export default AddedImageTile;
