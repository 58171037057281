import React, { useEffect, useState } from "react";
import logoName from "../images/dashboard/Logo1.svg";
import logo from "../images/dashboard/ultrastore-logo.png";
import Icon from "@mdi/react";
import {
  mdiArrowRightDropCircleOutline,
  mdiBell,
  mdiAccountCircle,
  mdiLogoutVariant,
  mdiMenu,
} from "@mdi/js";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../app/axiosInstance";
import NotifyMsg from "../Components/NotifyMsg";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { setSavedSignalLength } from "../../app/AuthSlice";

function Navbar() {
  const [isOpen, setToggle] = useState(false);
  const token = useSelector((state) => state.authorization.token);
  const user = useSelector((state) => state.authorization.userData);
  const savedSignalLength = useSelector(
    (state) => state.authorization.savedSignalLength
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [signalList, setSignalList] = useState();
  const [prevSignalLength, setSignalLength] = useState(0);
  const [notify, setNotify] = useState(0);

  useEffect(() => {
    const stockUpdateFetch = async () => {
      try {
        const response = await axiosInstance.get(
          "/stock/temporary_goods_update/",
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        );
        const fetchList = response.data.results;
        setSignalList(fetchList);

        if (savedSignalLength !== fetchList.length) {
          //Notifying the shopowner that the new goods being added
          toast.dismiss();
          if (fetchList.length < savedSignalLength) {
            toast.success(
              "Umekubali au kukataa ongezeko la bidhaa kikamilifu",
              {
                autoClose: true,
                position: "top-right",
              }
            );
          } else {
            toast.info("Bidhaa Mpya imeongezwa, inahitaji uhakiki", {
              autoClose: true,
              position: "top-right",
            });
          }
          dispatch(setSavedSignalLength(fetchList.length));
        }
      } catch (error) {
      }
    };
    stockUpdateFetch();
    const intervalId = setInterval(stockUpdateFetch, 10000);
    return () => clearInterval(intervalId);
  }, [savedSignalLength]);

  //logout function
  const callLogout = () => {
    const logOutRequest = async () => {
      try {
        const response = await axiosInstance.post(
          "/auth/logout/",
          {},
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        );
        localStorage.removeItem("caffein");
        navigate("/login");
      } catch (error) {
        toast("Kuna tatizo la kimtandao Tafadhali jaribu tena baadae");
      }
    };
    toast(
      <NotifyMsg
        Msg={"unahitaji kutoka nje ya mfumo?"}
        yesMethod={logOutRequest}
      />,
      {
        autoClose: false,
      }
    );
  };

  //navigate to profile
  const profilePage = () => {
    setToggle(!isOpen);
    navigate("/profile");
  };

  //Notification and profile toggle
  const toggleNotification = () => {
    setToggle(false);
    setIsNotificationOpen(!isNotificationOpen);
  };

  const toggleDropdown = () => {
    setIsNotificationOpen(false);
    setToggle(!isOpen);
  };

  const goToUpdateCenter = () => {
    navigate("/stock-approve");
    setIsNotificationOpen(!isNotificationOpen);
  };

  return (
    <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row border border-bottom border-4">
      <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
        <a className="navbar-brand brand-logo">
          <img
            src={logo}
            alt="logo"
            style={{ height: "2.5rem", width: "auto", objectFit: "contain" }}
          />
        </a>
        <a className="navbar-brand brand-logo-name">
          <img src={logoName} alt="logo" />
        </a>
        <a className="navbar-brand brand-logo-mini">
          <i>
            <Icon path={mdiArrowRightDropCircleOutline} size={1} />
          </i>
        </a>
      </div>
      <div className="navbar-menu-wrapper d-flex align-items-stretch">
        <button
          className="navbar-toggler navbar-toggler align-self-center"
          type="button"
          data-bs-toggle="minimize"
        >
          <span>
            <i>
              <Icon path={mdiMenu} size={1} />
            </i>
          </span>
        </button>
        <div class="w-100 h-100 d-flex justify-content-center align-items-center">
          <h4 class="fw-light">{user.user_company}</h4>
        </div>
        <ul className="navbar-nav navbar-nav-right">
          <li className="nav-item dropdown">
            <a
              className="nav-link count-indicator dropdown-toggle"
              id="notificationDropdown"
              data-bs-toggle="dropdown"
              onClick={toggleNotification}
            >
              <i>
                <Icon path={mdiBell} size={1} />
              </i>
              {signalList?.length > 0 && (
                <span className="count-symbol bg-danger"></span>
              )}
            </a>
            <div
              className={`dropdown-menu dropdown-menu-left navbar-dropdown preview-list ${
                isNotificationOpen ? "show" : ""
              } `}
              aria-labelledby="notificationDropdown"
            >
              <h6 className="p-3 mb-0">Notifications</h6>
              <div className="dropdown-divider"></div>
              <a className="dropdown-item preview-item">
                <div
                  className="preview-item-content d-flex align-items-start flex-column justify-content-center"
                  onClick={goToUpdateCenter}
                >
                  <h6 className="preview-subject font-weight-normal mb-1">
                    New Stock
                  </h6>
                  <p className="text-gray ellipsis mb-0">
                    {signalList
                      ? `${signalList?.length} Aprove required `
                      : "no new Notification"}{" "}
                  </p>
                </div>
              </a>
              <div className="dropdown-divider"></div>
            </div>
          </li>
          <li className="nav-item nav-profile dropdown dropleft">
            <a
              className="nav-link"
              id="profileDropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              onClick={toggleDropdown}
            >
              <div className="nav-profile-img">
                <img
                  src={require("../images/faces/AvatarIcon.jpg")}
                  alt="image"
                />
                <span className="availability-status online"></span>
              </div>
            </a>
            <div
              className={`dropdown-menu navbar-dropdown dropdown-menu-end ${
                isOpen ? "show" : ""
              }`}
              aria-labelledby="profileDropdown"
            >
              <a className="dropdown-item" onClick={profilePage}>
                <i className="me-2 text-success mdi mdi-account-circle">
                  <Icon path={mdiAccountCircle} size={1} />
                </i>
                Profile
              </a>
              <div className="dropdown-divider"></div>
              <a className="dropdown-item text-danger" onClick={callLogout}>
                <i className="me-2 mdi mdi-logout-variant"></i>{" "}
                <Icon path={mdiLogoutVariant} size={1} />{" "}
              </a>{" "}
            </div>
          </li>
        </ul>
        <button
          className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
          type="button"
          data-toggle="offcanvas"
        >
          <span className="mdi mdi-menu"></span>
        </button>
      </div>
    </nav>
  );
}

export default Navbar;
