import React, { useState } from "react";
import { mdiAccount, mdiMenuDown } from "@mdi/js";
import Icon from "@mdi/react";
import { useDispatch } from "react-redux";
import {
  setFilteredPosition,
  setStatisticalFilter,
} from "../../../app/AuthSlice";

function DropDownFilter({ position, linkName, isStatistical }) {
  //the component accept position valuable and linkName which is the list object for the dropdownlinks
  const [isOpen, setToggle] = useState(false);
  const dispatch = useDispatch();

  const toggleDropdown = () => {
    setToggle(!isOpen);
  };

  const filterPosition = (e) => {
    const { name } = e.target;
    if (!isStatistical) {
      dispatch(setFilteredPosition(name));
    } else {
      dispatch(setStatisticalFilter(name));
    }
    toggleDropdown();
  };

  const DropDownLinks = (name) => {
    return (
      <>
        <a className="dropdown-item " name={name} onClick={filterPosition}>
          {name}
        </a>
        <div class="dropdown-divider"></div>
      </>
    );
  };

  return (
    <div class="">
      <nav class="d-flex justify-content-start">
        <ul class="nav">
          <li class="nav-item dropdown bg-white rounded">
            <a
              className="nav-link text-secondary"
              id="timeFrameDropdown"
              data-bs-toggle="dropdown"
              onClick={toggleDropdown}
            >
              {position}
              <span className="mdi mdi-menu-down align-middle">
                <Icon path={mdiMenuDown} size={1} />
              </span>
            </a>
            <div
              class={` dropdown-menu dropdown-menu-left navbar-dropdown preview-list ${
                isOpen ? "show" : ""
              }`}
              aria-labelledby="timeFrameDropdown"
            >
              {linkName.map((item) => DropDownLinks(item))}
            </div>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default DropDownFilter;
