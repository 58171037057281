import React, { useEffect, useState } from "react";
import Icon from "@mdi/react";
import Footer from "../Components/footer";
import { mdiShopping, mdiMagnify, mdiViewList, mdiViewGrid } from "@mdi/js";
import StockLayout from "../Components/stock/StockLayout";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../app/axiosInstance";
import DropDownFilter from "../Components/dropDownFilter/DropDownFilter";
import { setFilteredPosition } from "../../app/AuthSlice";
import { setSavedGoodsTypes, setSearchWord } from "../../app/StockSlice";
import CustomLoader from "../Components/CustomLoader";
import { Link, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import PageHeader from "../Components/pageHeader";
import Loader from "../Components/loader";
import { Oval } from "react-loader-spinner";
import StockListLayout from "../Components/stock/StockListLayout";
import API from "../../services/api/API";

function Stock() {
  const token = useSelector((state) => state.authorization.token);
  const savedGoodsTypes = useSelector(
    (state) => state.stockStore.savedGoodsTypes
  );
  const filteredGoodsType =
    useSelector((state) => state.authorization.filteredPosition) || "all";
  const { state } = useLocation();
  const [dropDown, setDropDown] = useState([]);
  const dropDownArr = ["all"];
  const dispatch = useDispatch();
  const [goodsType, setGoodsType] = useState([]);
  const [isLoading, setIsLoading] = useState();

  useEffect(() => {
    dispatch(setFilteredPosition("all"));

    //Using the cashed goodsType or fetching them when required
    if (savedGoodsTypes.length > 0) {
      setGoodsType(savedGoodsTypes);
      const goodsTypeNames = savedGoodsTypes.map((item) => {
        return item.goods_type;
      });
      setDropDown(goodsTypeNames);
      setIsLoading(false);
    }

    const goodsTypeFetch = async () => {
      try {
        const response = await API.goodsTypeFetch(token);
        if (savedGoodsTypes.length !== response.data.length) {
          setGoodsType(response.data);
          dispatch(setSavedGoodsTypes(response.data));
          const goodsTypeNames = response.data.map((item) => {
            return item.goods_type;
          });
          setDropDown(goodsTypeNames);
          setIsLoading(false);
        }
      } catch (error) {
        //error handling required here
      }
    };
    goodsTypeFetch();
    window.history.replaceState(null, document.title, window.location.href);
  }, []);

  //Notification handling
  const addNotification = () => {
    state?.isEdited
      ? toast.success("Umefanikiwa kurekebisha bidhaa", {
          position: "top-right",
        })
      : toast.success("Umefanikiwa kuongeza bidhaa", {
          position: "top-right",
        });
  };

  //managing dropdown filtering selections
  if (dropDown) {
    dropDown.map((item) => dropDownArr.push(item));
  }

  //managing search filter
  const searchFilter = (e) => {
    const word = e.target.value;
    dispatch(setSearchWord(word));
  };

  const [listView, setListView] = useState(false);

  const changeView = () => {
    setListView(!listView);
  };

  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <PageHeader PageName="Stock" PageIcon={mdiShopping} />
        <div className="d-inline-flex justify-content-between w-100 align-items-center">
          {isLoading ? (
            <Oval height={15} width={15} color="green" />
          ) : (
            <div className="d-inline-flex gap-2 align-items-center">
              <div>
                <DropDownFilter
                  position={filteredGoodsType}
                  linkName={dropDownArr}
                />
              </div>
              {listView ? (
                <div
                  onClick={changeView}
                  className="text-success bg-light p-2 rounded"
                >
                  <Icon path={mdiViewGrid} size={1} />
                </div>
              ) : (
                <div
                  onClick={changeView}
                  className="text-success bg-light p-2 rounded"
                >
                  <Icon path={mdiViewList} size={1} />
                </div>
              )}
            </div>
          )}
          <div className="d-block">
            <form className="d-flex align-items-center">
              <div className="input-group rounded">
                <div className="input-group-prepend">
                  <i className="input-group-text border-0 text-dark">
                    <Icon path={mdiMagnify} size={1} />
                  </i>
                </div>
                <input
                  type="text"
                  className="form-control bg-light border-0"
                  placeholder="Search products"
                  onChange={searchFilter}
                />
              </div>
            </form>
          </div>
          <div className="text-center">
            <Link to={"/item-add"} className="nav-link">
              <a className="text-decoration-none text-white">
                <button className="btn btn-success me-2">Add new Item</button>
              </a>
            </Link>
          </div>
        </div>
        {isLoading ? (
          <Loader />
        ) : listView ? (
          <StockListLayout />
        ) : (
          <StockLayout
            isDashboard={false}
            goodsTypes={goodsType ? goodsType : ""}
          />
        )}
      </div>
      <Footer />
      {state?.isAdded ? addNotification() : ""}
      <ToastContainer autoClose={false} position="top-right" />
    </div>
  );
}

export default Stock;
