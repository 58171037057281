import React, { useEffect } from "react";
import PopUp from "../Components/popUp";
import { useSelector } from "react-redux";
import ToastNetworkError from "../Components/ToastNetworkError";
import axiosInstance from "../../app/axiosInstance";
import { useNavigate } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiClose, mdiEmail, mdiPhone, mdiWhatsapp } from "@mdi/js";
import {
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import { userInfo } from "os";

function StaffCard({ user }) {
  const token = useSelector((item) => item.authorization.token);
  const navigate = useNavigate();

  //Dialog
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  

  //handling button click to user view
  const toUserView = (e) => {
    const userId = e.currentTarget.getAttribute("name");
    navigate("/staff-view", { state: { userId } });
  };
  return (
    <div class="col-md-4 mt-4 px-2">
      <div class="p-3 mt-2 bg-white rounded">
        <div
          class="row"
          onClick={toUserView}
          name={user.id}
          style={{ cursor: "pointer" }}
        >
          <div class="col-5" style={{ marginTop: "-2rem" }}>
            <img
              alt="img"
              src={require("../images/faces/AvatarIcon.jpg")}
              class="w-100 shadow rounded"
              style={{
                height: "6rem",
                objectFit: "cover",
                objectPosition: "top",
                objectPosition: "center",
              }}
            />
          </div>
          <div class="col-7">
            <h4 class="text-truncate">
              {user.first_name} {user.last_name}
            </h4>
            <div class="text-small text-truncate">{user.groups[0]}</div>
          </div>
        </div>
        <div class="my-2">
          <div class="pt-4">
            <div class="d-flex justify-content-between">
              <div class="d-inline-flex gap-2 wrap">
                <div
                  class="d-flex justify-content-center align-items-center bg-secondary rounded text-light"
                  style={{ height: "1.5rem", width: "1.5rem" }}
                >
                  <Tooltip title={user.phone_number}>
                    <Icon path={mdiWhatsapp} size={0.7} />
                  </Tooltip>
                </div>
                <div
                  class="d-flex justify-content-center align-items-center bg-secondary rounded text-light"
                  style={{ height: "1.5rem", width: "1.5rem" }}
                >
                  <Tooltip title={user.phone_number}>
                    <Icon path={mdiPhone} size={0.7} />
                  </Tooltip>
                </div>
                <div
                  class="d-flex justify-content-center align-items-center bg-secondary rounded text-light"
                  style={{ height: "1.5rem", width: "1.5rem" }}
                >
                  <Tooltip title={user.email}>
                    <Icon path={mdiEmail} size={0.7} />
                  </Tooltip>
                </div>
              </div>
              <div>
                <React.Fragment>
                  <Tooltip title="Futa">
                  <Button
                    variant=""
                    onClick={handleClickOpen}
                    className="p-0 m-0"
                  >
                    <div
                      class="d-flex justify-content-center align-items-center bg-danger rounded-circle text-light"
                      style={{ height: "1.5rem", width: "1.5rem" }}
                    >
                      
                        <Icon path={mdiClose} size={0.7} /> 
                    </div>
                  </Button>
                  </Tooltip>
                  <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {"Futa mfanyakazi"}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        Je unataka kufuta mfanyakazi{" "}
                        <span className="text-uppercase text-primary">
                          {user.first_name} {user.last_name}
                        </span>
                        ?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose}>Hapana</Button>
                      <Button onClick={handleClose} autoFocus>
                        Ndiyo
                      </Button>
                    </DialogActions>
                  </Dialog>
                </React.Fragment>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StaffCard;
