import React, { useEffect, useState } from "react";
import axiosInstance from "../../app/axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import StockApproveData from "./StockApproveData";
import CustomLoader from "../Components/CustomLoader";
import { mdiBookmarkCheck } from "@mdi/js";
import PageHeader from "../Components/pageHeader";
import { Link } from "react-router-dom";
import Footer from "../Components/footer";
import { ToastContainer } from "react-toastify";

function StockApproveList() {
  const token = useSelector((state) => state.authorization.token);
  const [isLoading, setisLoading] = useState(true);
  const [temporaryGoods, setTemporaryGoods] = useState();
  const [staff, setStaffs] = useState();
  const [reload, setReload] = useState(true);

  useEffect(() => {
    const temporaryGoodsUpdateFetch = async () => {
      try {
        const response = await axiosInstance.get(
          "/stock/temporary_goods_update/",
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        );
        setTemporaryGoods(response.data.results);
        setisLoading(false);
      } catch (error) {}
    };
    temporaryGoodsUpdateFetch();

    const staffFetch = async () => {
      try {
        const response = await axiosInstance.get("/auth/user_registration/", {
          headers: {
            Authorization: `Token ${token}`,
          },
        });
        setStaffs(response.data);
      } catch (error) {}
    };
    staffFetch();
  }, [reload]);

  //make changes to the list when the action is done
  const listChanges = (itemId) => {
    const remainingTemporaryGoods = temporaryGoods.filter(
      (item) => item.id !== parseInt(itemId)
    );
    setTemporaryGoods(remainingTemporaryGoods);
    setReload(!reload);
  };

  //get staff username from id
  const getStaff = (staffId) => {
    if (staff?.length > 0) {
      const name = staff.filter((person) => person.id === parseInt(staffId));
      return name[0].username;
    } else {
      return staffId;
    }
  };

  return isLoading ? (
    <div className="row py-2">
      <div className="col-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body bg-white">
            <h4 className="card-title">Goods Update to be Approved</h4>
            <CustomLoader size={50} />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <>
      <div className="main-panel">
        <div class="content-wrapper">
          <PageHeader PageName="Goods Updates" PageIcon={mdiBookmarkCheck} />
          <div class="d-inline-flex justify-content-between w-100 align-items-center">
            {/*here stay the filter */}
            <div class="text-center">
              <Link to={"/item-add"} className="nav-link">
                <button type="button" className="btn btn-success">
                  Add New Good
                </button>
              </Link>
            </div>
          </div>
          <div className="row py-2">
            <div className="col-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body bg-white">
                  <h4 className="card-title"></h4>
                  <div>
                    <table className="table table-responsive table-hover text-start">
                      <tr>
                        <th>Image</th>
                        <th>Date</th>
                        <th>Good number</th>
                        <th>stock quantity</th>
                        <th>amount to approve</th>
                        <th>updated by</th>
                        <th></th>
                      </tr>
                      {temporaryGoods.length > 0 ? (
                        temporaryGoods.map((item) => (
                          <StockApproveData
                            column1Data={item.goods}
                            column2Data={item.quantity_available}
                            column3Data={item.quantity_added}
                            column4Data={getStaff(item.updated_by)}
                            dateAdded={item.updated_on}
                            itemID={item.id}
                            remover={listChanges}
                          />
                        ))
                      ) : (
                        <p className="text-centre my-3">No new update</p>
                      )}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <ToastContainer position="top-right" />
      </div>
    </>
  );
}

export default StockApproveList;
