import React from "react";

function DateConverter(datePassed) {
  const date = new Date(datePassed);
  const options = {
    day: "numeric",
    month: "long",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  const formatedDate = date.toLocaleDateString("en-US", options);
  return formatedDate;
}

export default DateConverter;
