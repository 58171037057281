import React, { useEffect, useState } from "react";
import DateConverter from "./DateConverter";
import { useSelector } from "react-redux";
import axiosInstance from "../../app/axiosInstance";
import ToastNetworkError from "./ToastNetworkError";

export const ComponentToPrint = React.forwardRef((props, ref) => {
  const token = useSelector((state) => state.authorization.token);
  const [data, setData] = useState();
  const [orderGoods, setOrderGoods] = useState();
  const user = useSelector((state) => state.authorization.userData);
  let itemsCount;

  useEffect(() => {
    setData(props.data);
    setOrderGoods(props.data.order_goods_details);
  }, []);

  if (data) {
    itemsCount = data.order_goods_details.reduce(
      (sum, item) => sum + item.goods_quantity,
      0
    );
  }

  return (
    <div ref={ref}>
      {data && (
        <>
          <div className="p-4 bg-white rounded w-100">
            <div className="row justify-content-between">
              <div className="col-6">
                <h2 className="text-uppercase mb-0">Receipt</h2>
                <div className="mt-3 fw-bolder">Order#: {data?.order_no}</div>
                <div>
                  <div className="text-small text-secondary">
                    Date: {DateConverter(data.date_added)}
                  </div>
                  <div className="text-small text-secondary">
                    Authorizer: {data.cash_authorizer}
                  </div>
                </div>
              </div>
              <div className="col-6 text-center">
                <div>
                  <h4 className="mb-0">{user?.user_company}</h4>
                </div>
                <div>
                  <p className="p-0 m-0 text-uppercase">COMPANY</p>
                </div>
              </div>
            </div>
            <div className="mt-3">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <td></td>
                      <td></td>
                      <td>
                        <div className="py-1">TOTAL ITEMS</div>
                      </td>
                      <th>
                        <div className="py-1">{itemsCount && itemsCount}</div>
                      </th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>

            <div className="mt-3">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Product</th>
                      <th>Quantity</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderGoods &&
                      orderGoods.map((item, index) => (
                        <tr key={index}>
                          <td className="text-wrap">{item.goods_number}</td>
                          <td>{item.goods_quantity}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="container py-3 text-center">
              <div className="text-secondary text-small">ultramanager.live</div>
              <div className="text-secondary text-small">Tanzania</div>
              <div className="text-secondary text-small">© 2024 Copyright</div>
            </div>
          </div>
        </>
      )}
    </div>
  );
});
