import React from "react";
import Icon from "@mdi/react";
import { mdiWhatsapp, mdiTwitter, mdiFacebook, mdiInstagram } from "@mdi/js";

function Footer() {
  return (
    <footer className="text-center text-white bg-white">
      <div className="container p-4">
        <div>
          <a href="#!" role="button">
            <i className="p-2 text-secondary" style={{ fontSize: "20px" }}>
              <Icon path={mdiWhatsapp} size={1} />
            </i>
          </a>
          <a href="#!" role="button">
            <i className="p-2 text-secondary" style={{ fontSize: "20px" }}>
              <Icon path={mdiTwitter} size={1} />
            </i>
          </a>
          <a href="#!" role="button">
            <i className="p-2 text-secondary" style={{ fontSize: "20px" }}>
              <Icon path={mdiFacebook} size={1} />
            </i>
          </a>
          <a href="#!" role="button">
            <i className="p-2 text-secondary" style={{ fontSize: "20px" }}>
              <Icon path={mdiInstagram} size={1} />
            </i>
          </a>
        </div>
        <div className="">
          <div className="text-center p-3 text-dark">© 2024 Copyright</div>
          <div className="text-success">ultrastore Tanzania</div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
