import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function SidePanelProfile() {
  const token = useSelector((state) => state.authorization.token);
  const user = useSelector((state) => state.authorization.userData);

  return (
    <li className="nav-item nav-profile">
      <Link to="/profile" className="nav-link">
        <div className="nav-profile-image">
          <img
            src={require("../../images/faces/AvatarIcon.jpg")}
            alt="profile"
          />
          <span className="login-status online"></span>
        </div>
        <div className="nav-profile-text d-flex flex-column">
          <span
            className="font-weight-bold mb-2 py-2 text-truncate"
            style={{ width: "8rem" }}
          >
            {user?.first_name} {user?.last_name}
          </span>
          <span className="text-secondary text-small">
            {user?.groups[0] === "shopowner" ? "Shop Owner" : user?.groups[0]}
          </span>
        </div>
      </Link>
    </li>
  );
}

export default SidePanelProfile;
