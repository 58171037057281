import { Navigate, useLocation } from "react-router-dom";
import Layout from "./layout/Layout";
import axiosInstance from "../app/axiosInstance";
import { useState, useEffect } from "react";
import Loader from "./Components/loader";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../app/AuthSlice";

function PrivateRoute() {
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [status, setStatus] = useState(10);
  const token = useSelector((state) => state.authorization.token);
  const userId = localStorage.getItem("caffeinId");
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchUser = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.get(
          `/auth/user_profile/${userId}/`,
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        );
        setStatus(response.status);
        setIsAuthenticated(response.status === 200); // Update isAuthenticated based on response status
        dispatch(setUser(response.data));
      } catch (error) {}
      setLoading(false);
    };

    fetchUser();
  }, []);

  return loading ? (
    <Loader />
  ) : isAuthenticated ? (
    <Layout />
  ) : (
    <Navigate to="/login" />
  );
}

export default PrivateRoute;
