import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./components/Login";
import PrivateRoute from "./components/PrivateRoute";
import MainPanel from "./components/layout/MainPanel";
import Profile from "./components/profile/profile";
import Stock from "./components/stock/stock";
import Orders from "./components/orders/orders";
import Shops from "./components/shops/shops";
import Staffs from "./components/staffs/staffs";
import StockItemView from "./components/stock/StockItemView";
import ItemForm from "./components/stock/ItemForm";
import OrderView from "./components/orders/OrderView";
import CreateOrder from "./components/orders/CreateOrder";
import AddStaff from "./components/staffs/addStaff";
import EditProfile from "./components/profile/editProfile";
import StaffView from "./components/staffs/staffView";
import ChangePassword from "./components/profile/changePassword";
import AddStore from "./components/shops/addStore";
import StockApproveList from "./components/stock/StockApproveList";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<PrivateRoute />}>
            <Route index element={<MainPanel />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/staffs" element={<Staffs />} />
            <Route path="/orders" element={<Orders />} />
            <Route path="/stock" element={<Stock />} />
            <Route path="/stores" element={<Shops />} />
            <Route path="/staffs" element={<Staffs />} />
            <Route path="/item-view" element={<StockItemView />} />
            <Route path="/item-add" element={<ItemForm />} />
            <Route path="/stock-approve" element={<StockApproveList />} />
            <Route path="/order-view" element={<OrderView />} />
            <Route path="/create-order" element={<CreateOrder />} />
            <Route path="/addStore" element={<AddStore />} />
            <Route path="/addStaff" element={<AddStaff />} />
            <Route path="/staff-view" element={<StaffView />} />
            <Route path="/editProfile" element={<EditProfile />} />
            <Route path="/change-password" element={<ChangePassword />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
