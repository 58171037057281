import React from "react";
import Loader from "../Components/loader";
import CustomLoader from "../Components/CustomLoader";
import PopUp from "../Components/popUp";
import { useSelector } from "react-redux";
import axiosInstance from "../../app/axiosInstance";
import ToastNetworkError from "../Components/ToastNetworkError";
import { ToastContainer, toast } from "react-toastify";
import Icon from "@mdi/react";
import { Tooltip } from "@mui/material";
import { mdiAccount, mdiMapMarker, mdiWhatsapp } from "@mdi/js";
import { Transform } from "stream";

function ShopCards({
  shopId,
  shopName,
  shopLocation,
  shopKeeper,
  created,
  loading,
  deleteAction,
}) {
  const token = useSelector((state) => state.authorization.token);
  const storeId = parseInt(shopId);
  const deleteStore = async () => {
    toast.info("Tafadhali Subiri...");
    await axiosInstance
      .delete(`/stock/store/${storeId}/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 204) {
          toast.success("umefanikiwa Kufuta Store kikamilifu", {
            autoClose: true,
          });
          deleteAction();
        }
      })
      .catch((error) => {
        toast.dismiss();
        if (error.response.status === 500) {
          toast.info(
            "kuna baadhi ya bidhaa zako umezisajili kwa Store hii, hauwezi kuifuta kama bidhaa bado zipo.",
            {
              autoClose: false,
            }
          );
        } else {
          ToastNetworkError();
        }
      });
  };

  const created_arr = created?.split("at");
  const date = created_arr[0]?.trim();
  const time = created_arr[1]?.trim();

  return (
    <div class="col-md-4 mt-4 px-2">
      {loading ? (
        <CustomLoader />
      ) : (
        <div className="shadow position-relative">
          <div>
            <img
              alt=""
              src={require("../images/dashboard/ImageIcon.jpg")}
              style={{ height: "12rem", objectFit: "cover" }}
              class="w-100"
            />
          </div>

          <div class="p-3 rounded bg-light">
            <div
              class="d-flex align-content-between flex-wrap"
              style={{ cursor: "pointer" }}
            >
              <div class="w-100 pb-2">
                <h4 class="text-truncate">{shopName}</h4>
                <div class="text-small text-truncate">{shopId}</div>
              </div>
              <div class="w-100">
                <div class="d-flex w-100 justify-content-between align-items-center">
                  <div class="d-inline-flex gap-2 wrap">
                    <div
                      class="d-flex justify-content-center align-items-center bg-success rounded text-light"
                      style={{ height: "1.5rem", width: "1.5rem" }}
                    >
                      <Tooltip title={shopLocation}>
                        <Icon path={mdiMapMarker} size={0.7} />
                      </Tooltip>
                    </div>
                    <div
                      class="d-flex justify-content-center align-items-center bg-success rounded text-light"
                      style={{ height: "1.5rem", width: "1.5rem" }}
                    >
                      <Tooltip title={shopKeeper}>
                        <Icon path={mdiAccount} size={0.7} />
                      </Tooltip>
                    </div>
                    <div
                      class="d-flex justify-content-center align-items-center bg-success rounded text-light"
                      style={{ height: "1.5rem", width: "1.5rem" }}
                    >
                      <Tooltip title="Tazama katalogi">
                        <Icon path={mdiWhatsapp} size={0.7} />
                      </Tooltip>
                    </div>
                  </div>
                  <div className="text-end">
                    <div
                      class="fw-light text-truncate"
                      style={{ fontSize: "0.8rem" }}
                    >
                      {date}
                    </div>
                    <div
                      class="fw-light text-truncate"
                      style={{ fontSize: "0.8rem" }}
                    >
                      {time}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ShopCards;
