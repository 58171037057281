import React, { useEffect, useRef, useState } from "react";
import Footer from "../Components/footer";
import { mdiBookmarkCheck, mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import { useDispatch, useSelector } from "react-redux";
import { setSavedOrderDiscount, setSavedStores } from "../../app/AuthSlice";
import { setSavedGoods } from "../../app/StockSlice";
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import NotifyMsg from "../Components/NotifyMsg";
import PageHeader from "../Components/pageHeader";
import OrderDiscount from "./OrderDiscount";
import Select from "react-select";
import API from "../../services/api/API";
import {
  Box,
  Paper,
  Table,
  TableCell,
  TableContainer,
  InputLabel,
  FormControl,
  TableRow,
  TextField,
  TableBody,
  TableHead,
  MenuItem,
} from "@mui/material";
import { blue } from "@mui/material/colors";

function CreateOrder({ props }) {
  const token = useSelector((state) => state.authorization.token);
  const savedGoodsUpdate = useSelector(
    (state) => state.stockStore.savedGoodsUpdate
  );
  const savedGoods = useSelector((state) => state.stockStore.savedGoods);
  const savedStores = useSelector((state) => state.authorization.savedStores);
  const savedOrderDiscount = useSelector(
    (state) => state.authorization.savedOrderDiscount
  );
  const [store, setStore] = useState();
  const [goodsAvailable, setGoodsAvailable] = useState([]);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [loadingStore, setLoadingStore] = useState(true);
  const [asyncLoading, setAsyncLoading] = useState(true);
  const [selectedItem, setSelectedItem] = useState();
  const [goodsOrderArr, setGoodsOrderArr] = useState([]);
  const [orderCaption, setOrderCaption] = useState("");
  const navigate = useNavigate();
  const { state } = useLocation();
  const [selectedOption, setSelectedOption] = useState();
  const [errorFields, setErrorField] = useState({
    goods: true,
    unit_ordered: true,
    price: true,
    unit: true,
    customer_username: true,
    customer_phone: true,
  });
  const [itemToPush, setItemToPush] = useState({
    goods: 0,
    unit_ordered: 0,
    unit: 0,
    price: 0,
  });
  const [itemNumberData, setItemNumberData] = useState({
    unit_ordered: 0,
    price: 0,
  });
  const [customer, setCustomer] = useState({
    customer_username: "",
    customer_phone: "",
  });

  let requiredOption;

  useEffect(() => {
    dispatch(setSavedOrderDiscount(0));
    //load the saved goodsAvailable or Fetching them through the API
    if (savedGoods.length > 0) {
      setGoodsAvailable(savedGoods);
      setIsLoading(false);
    }

    //fetch Goods for the first time or async if the data cache available
    const goodsAvailableFetch = async () => {
      try {
        const response = await API.goodsFetch(token);
        //comparing the data before updating the page for the updated available goods

        if (savedGoods.length !== response.data.result.length) {
          setGoodsAvailable(response.data.result);
          dispatch(setSavedGoods(response.data.result));
          setIsLoading(false);
          setAsyncLoading(false);
        }
      } catch (error) {}
    };
    goodsAvailableFetch();

    //loading the saved stores or fetch the new one from the Api
    if (savedStores.length > 0) {
      setStore(savedStores);
      setLoadingStore(false);
    }
    const storeFetch = async () => {
      try {
        const response = await API.StoreFetch(token);
        //compare updates and the stored data
        if (savedStores.length !== response.data.result) {
          setStore(response.data.result);
          dispatch(setSavedStores(response.data.result));
          setLoadingStore(false);
        }
      } catch (error) {
        toast.error("kuna tatizo la kimtandao jaribu tena", {
          autoClose: true,
        });
      }
    };
    storeFetch();
  }, []);

  //method to populate data from the instance
  const instanceData = () => {
    if (state && savedGoods && savedGoods.length > 0) {
      const updatedGoodsArray = state.orderDetails.order_goods_details.map(
        (item) => {
          const itemId = savedGoods.find(
            (x) => x.goods_number === item.goods_number
          );
          const itemData = {
            goods: itemId?.id ? itemId.id : item.goods_number,
            unit_ordered: item.unit_ordered,
            price: item.price,
          };
          return itemData;
        }
      );
      setGoodsOrderArr(updatedGoodsArray);
    }
  };

  // Call instanceData whenever savedGoodsUpdate changes
  useEffect(() => {
    instanceData();
    state &&
      dispatch(setSavedOrderDiscount(state?.orderDetails.order_discount));
  }, [savedGoodsUpdate, state]);

  //method for setting fields to default
  const defaulting = () => {
    setErrorField({
      goods: true,
      unit_ordered: true,
      price: true,
      unit: true,
      customer_username: true,
      customer_phone: true,
    });
    setItemToPush({
      goods: 0,
      unit_ordered: 0,
      unit: 0,
      price: 0,
    });
    setItemNumberData({
      unit_ordered: 0,
      price: 0,
    });
    setSelectedItem(null);
    setSelectedOption(null);
  };

  //method to get store name out of store Id
  const getStoreName = (storeName) => {
    if (store?.length > 0) {
      const storeName = store.filter((item) => item.store_name === storeName);
      return storeName[0]?.store_name ? storeName[0]?.store_name : "";
    } else {
      return "";
    }
  };

  //handling input changes
  const handleInputData = (e) => {
    const { name, value } = e.target;
    const filteredValues = value
      .split(" ")
      .filter((item) => item !== "").length;
    let numericValue = value;
    if (filteredValues < 2) {
      numericValue = parseFloat(value.replace(/,/g, ""));
    }
    if (!isNaN(numericValue) || value === "") {
      const displayedData = value === "" ? "" : numericValue.toLocaleString();
      setItemNumberData({ ...itemNumberData, [name]: displayedData });
      if (name === "customer_phone") {
        const phone_number = "+255" + numericValue.toString();
        setCustomer({ ...customer, ["customer_phone"]: phone_number });
        setErrorField({ ...errorFields, [name]: true });
      } else {
        setItemToPush({
          ...itemToPush,
          [name]: isNaN(numericValue) ? "" : numericValue,
        });
      }
    } else if (name === "customer_username" || name === "customer_phone") {
      setCustomer({ ...customer, [name]: value });
      setErrorField({ ...errorFields, [name]: true });
    } else {
      setItemToPush({ ...itemToPush, [name]: value });
    }
  };

  //handling unit Selection
  const handleUnitChange = (e) => {
    const { name, value } = e.target;
    setItemToPush({ ...itemToPush, [name]: value.toUpperCase() });
  };

  //add item to the list of order Goods
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Add smooth scrolling behavior
    });
  };

  const componentRef = useRef(null);
  const scrollToComponent = () => {
    componentRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const itemToList = () => {
    //error checking before adding item to the list
    setErrorField(() => {
      const updatedErrorField = { ...errorFields };
      for (const key in itemToPush) {
        if (!itemToPush[key]) {
          updatedErrorField[key] = false;
        } else {
          updatedErrorField[key] = true;
        }
      }
      return updatedErrorField;
    });

    const pushingItem = () => {
      if (goodsOrderArr.some((item) => item.goods === itemToPush.goods)) {
        toast.error(
          "Bidhaa Hii tayari ipo kwenye mkeka, huwezi ongeza mala mbili",
          {
            position: "top-right",
          }
        );
      } else {
        if (
          !itemToPush.goods ||
          !itemToPush.unit_ordered ||
          !itemToPush.price
        ) {
          toast.error("Tafadhari jaza sehemu zote husika", {
            position: "top-right",
          });
        } else {
          if (
            itemToPush.unit_ordered <= selectedItem.goods_available_for_sales
          ) {
            const goodsArr = goodsOrderArr;
            goodsArr.push(itemToPush);
            setGoodsOrderArr(goodsArr);
            scrollToTop();
            toast.success("Bidhaa imeongezwa katika mkeka", {
              position: "top-right",
            });
            defaulting();
          } else {
            toast.error(
              "umeweka idadi kubwa ya bidhaa, zingatia ujazo wa store",
              {
                position: "top-right",
              }
            );
          }
        }
      }
    };
    pushingItem();
  };

  //method to return human readable goods data
  const getItemName = (itemId) => {
    if (state) {
    }
    const itemData = goodsAvailable.filter((item) => item.id === itemId);
    return itemData[0]?.goods_number;
  };
  //Removing Item from the goodsOrderArray
  const removingItem = (removeId) => {
    const remainingItems = goodsOrderArr.filter(
      (item) => item.goods !== removeId
    );
    setGoodsOrderArr(remainingItems);
  };

  //edit the available goodsOrder
  const editingItem = (itemId) => {
    removingItem(itemId);
    const item = goodsOrderArr.filter((item) => item.goods === itemId);
    setItemToPush(item[0]);

    const selectedItem = goodsAvailable.filter((item) => item.id === itemId);
    setSelectedItem(selectedItem[0]);
    const returnedOption = requiredOption?.filter(
      (item) => item.value == itemId
    );
    setSelectedOption(returnedOption[0]);
    setItemNumberData({
      ...itemNumberData,
      ["unit_ordered"]: item[0].unit_ordered,
      ["price"]: item[0].price,
    });
    scrollToComponent();
  };

  //item total numbers
  const getTotal = () => {
    const totalPrice = () => {
      const itemTotalPrice = [];
      goodsOrderArr.map((item) => {
        itemTotalPrice.push(item.price * item.unit_ordered);
      });
      const sum = itemTotalPrice.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );
      return sum;
    };
    const totalCount = () => {
      const arr = [];
      goodsOrderArr.map((item) => arr.push(item.unit_ordered));
      const sum = arr.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );
      return sum;
    };
    const totalQuantity = totalCount();
    const overallPrice = totalPrice();
    const VAT = Math.floor(totalPrice() * (18 / 118));
    return { totalQuantity, overallPrice, VAT };
  };

  //Submitting data to the API
  const handleCaptionChange = (e) => {
    setOrderCaption(e.target.value);
  };

  const notify = (e) => {
    e.preventDefault();
    if (customer.customer_username === "" || customer.customer_phone === "") {
      const err = { ...errorFields };
      customer.customer_username === "" && (err.customer_username = false);
      customer.customer_phone === "" && (err.customer_phone = false);
      setErrorField(err);
      toast.error("Jaza taarifa za mteja tafadhari", {
        autoClose: true,
        position: "top-right",
      });
    } else if (
      customer?.customer_phone.length < 13 ||
      customer.customer_phone.length > 13
    ) {
      const err = { ...errorFields };
      err.customer_phone = false;
      setErrorField(err);
      toast.error("Number ya simu ya mteja sio sahihi", {
        autoClose: true,
        position: "top-right",
      });
    } else if (goodsOrderArr.length > 0) {
      toast.dismiss();
      toast(
        <NotifyMsg
          Msg={"umekagua order hii na upo Tayari kuitengeneza"}
          yesMethod={handleSubmit}
        />,
        {
          autoClose: false,
        }
      );
    } else {
      toast.error("Jaza Taarifa za order Ndio utume", {
        position: "top-right",
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    toast.dismiss();
    toast.info("Tafadhari subiri...", {
      autoClose: false,
    });
    //data
    const order_no = state?.orderDetails?.order_no;
    const order = {
      order_goods: goodsOrderArr,
      order_caption: orderCaption,
      order_discount: savedOrderDiscount,
      customer_details: customer,
    };
    const processOrder = async () => {
      try {
        const response = state
          ? await API.editOrder({ token, order, order_no })
          : await API.createOrder({ order, token });

        const response_data = response?.data;

        if (response.status === 201 || response.status === 200) {
          //removing the order items from the saved goodUpdate to allow next update
          const arrIds = [];
          goodsOrderArr.map((item) => arrIds.push(item.goods));
          const remainingGoodsUpdate = goodsAvailable.filter(
            (item) => !arrIds.some((x) => x === item.id)
          );
          dispatch(setSavedGoods(remainingGoodsUpdate));
          navigate("/orders", { state: { isAdded: true } });
        } else if (response.status === 400 && response_data?.non_field_errors) {
          toast.dismiss();
          const error_msg = response_data?.non_field_errors[0];
          toast.error(error_msg, { autoClose: true });
        }
      } catch (error) {
        toast("Kuna tatizo la kimtandao, jaribu tena", { autoClose: true });
      }
    };
    processOrder();
  };

  //implementing new dropdown search bar

  if (goodsAvailable) {
    const newGoodsAvailable = [];
    goodsAvailable.map((item) =>
      newGoodsAvailable.push({
        value: item.id,
        label: item.goods_number,
      })
    );
    requiredOption = newGoodsAvailable;
  }

  const handleSearchBar = (e) => {
    const { value } = e.target;
    setSelectedOption(value);
    //set selected item
    const selected = goodsAvailable.filter((item) => item.id === value);
    setSelectedItem(selected[0]);
    setItemToPush({ ...itemToPush, ["goods"]: selected[0].id });
  };

  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <PageHeader PageName="Edit order" PageIcon={mdiBookmarkCheck} />
        <div>
          <div className="">
            <div className="h4 px-2 text-secondary">Add/Edit order</div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 grid-margin stretch-card">
            <section>
              <form onSubmit={notify}>
                <div className="card bg-transparent">
                  <div className=" row">
                    <div className="col-md-3 text-secondary" ref={componentRef}>
                      <div
                        className="bg-white p-3 rounded"
                        style={{ height: "32rem" }}
                      >
                        <div className="h4">Customer Details</div>
                        <div className="form-control p-0 mb-1 border-0">
                          <Box
                            component="form"
                            noValidate
                            autoComplete="on"
                            required
                          >
                            <TextField
                              id="CustomerName"
                              label="Customer username"
                              name="customer_username"
                              onChange={handleInputData}
                              error={!errorFields.customer_username}
                              fullWidth
                              variant="outlined"
                              size="small"
                            />
                          </Box>
                        </div>
                        <div className="form-control p-0 mb-1 border-0">
                          <Box
                            component="form"
                            noValidate
                            autoComplete="on"
                            required
                          >
                            <TextField
                              id="customerContact"
                              label="Phone number"
                              name="customer_phone"
                              onChange={handleInputData}
                              error={!errorFields.customer_phone}
                              fullWidth
                              variant="outlined"
                              size="small"
                            />
                          </Box>
                        </div>

                        <div className="h4 pt-4">Item Details</div>

                        <div className="form-group">
                          <div className="form-control p-0 border-0">
                            <Box
                              component="form"
                              noValidate
                              autoComplete="off"
                              required
                            >
                              <TextField
                                id="selectItem"
                                select
                                type="text"
                                label="Select Item"
                                name="Select_Item"
                                fullWidth
                                variant="outlined"
                                size="small"
                                onChange={handleSearchBar}
                              >
                                {requiredOption?.map((good) => (
                                  <MenuItem value={good.value}>
                                    {good.label}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Box>
                          </div>
                        </div>

                        <div className="form-group d-inline-flex gap-2 w-100">
                          <div className="form-control p-0 border-0">
                            <Box
                              component="form"
                              noValidate
                              autoComplete="on"
                              required
                            >
                              <TextField
                                id="AvailableItem"
                                type="number"
                                label="Available"
                                name="Available"
                                fullWidth
                                variant="outlined"
                                size="small"
                                disabled
                                InputProps={{
                                  min: 0,
                                }}
                                value={
                                  selectedItem
                                    ? selectedItem.goods_available_for_sales
                                    : 0
                                }
                              />
                            </Box>
                          </div>
                          <div className="form-control p-0 border-0">
                            <Box
                              component="form"
                              noValidate
                              autoComplete="on"
                              required
                            >
                              <TextField
                                id="PriceMargin"
                                type="number"
                                label="Price"
                                name="PriceMargin"
                                fullWidth
                                variant="outlined"
                                size="small"
                                disabled
                                InputProps={{
                                  min: 0,
                                }}
                                value={
                                  selectedItem
                                    ? selectedItem?.lower_price_margin
                                    : 0
                                }
                              />
                            </Box>
                          </div>
                        </div>

                        <div className="form-group d-inline-flex gap-2 w-100">
                          <div className="form-control p-0 border-0">
                            <Box
                              component="form"
                              noValidate
                              autoComplete="on"
                              required
                            >
                              <TextField
                                id="itemNumber"
                                type="text"
                                label="Quantity"
                                name="unit_ordered"
                                InputProps={{
                                  min: 1,
                                }}
                                fullWidth
                                variant="outlined"
                                size="small"
                                value={itemNumberData.unit_ordered}
                                onChange={handleInputData}
                              />
                            </Box>
                          </div>
                          <div className="form-control p-0 border-0">
                            <Box
                              component="form"
                              noValidate
                              autoComplete="off"
                              required
                            >
                              <TextField
                                id="itemUnit"
                                select
                                type="text"
                                label="Unit"
                                name="unit"
                                fullWidth
                                variant="outlined"
                                size="small"
                                onChange={handleUnitChange}
                              >
                                <MenuItem value="Pieces" selected>
                                  Piece
                                </MenuItem>
                                <MenuItem value="Pair">Pair</MenuItem>
                                <MenuItem value="Dozen">Dozen</MenuItem>
                                <MenuItem value="Crate">Crate</MenuItem>
                                <MenuItem value="Gross">Gross</MenuItem>
                              </TextField>
                            </Box>
                          </div>
                        </div>

                        <div className="form-control p-0 border-0">
                          <Box
                            component="form"
                            noValidate
                            autoComplete="on"
                            required
                          >
                            <TextField
                              id="beiKuuza"
                              label="Bei ya kuuzia"
                              name="price"
                              fullWidth
                              type="text"
                              variant="outlined"
                              size="small"
                              onChange={handleInputData}
                              value={itemNumberData.price}
                            />
                          </Box>
                        </div>
                        <div className="text-center pt-4">
                          <span
                            className="btn btn-success me-2"
                            onClick={itemToList}
                          >
                            Add item
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-9">
                      <div
                        className="bg-white p-3 rounded text-secondary"
                        style={{ height: "32rem" }}
                      >
                        <div className="h4">Product added</div>
                        <div className="h-75">
                          <TableContainer component={Paper} className="h-100">
                            <Table
                              sx={{ min: 650 }}
                              size="small"
                              aria-label="a dense table"
                              stickyHeader
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell>Product name</TableCell>
                                  <TableCell align="right"> Amount</TableCell>
                                  <TableCell align="right"> Price </TableCell>
                                  <TableCell align="right"> Action</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {goodsOrderArr?.length > 0 &&
                                  goodsOrderArr.map((item, index) => (
                                    <TableRow key={index}>
                                      <TableCell>
                                        {`${index + 1}`}.{" "}
                                        {
                                          goodsAvailable.filter(
                                            (good) => good.id === item.goods
                                          )[0].goods_number
                                        }
                                      </TableCell>
                                      <TableCell align="right">
                                        {item.unit_ordered}
                                      </TableCell>
                                      <TableCell align="right">
                                        {(
                                          item.price * item.unit_ordered
                                        ).toLocaleString()}
                                      </TableCell>
                                      <TableCell align="right">
                                        <span>
                                          <a
                                            className="text-success"
                                            onClick={() =>
                                              editingItem(item.goods)
                                            }
                                            style={{ cursor: "pointer" }}
                                          >
                                            Edit
                                          </a>
                                        </span>

                                        <a
                                          className="text-decoration-none"
                                          onClick={() =>
                                            removingItem(item.goods)
                                          }
                                          style={{ cursor: "pointer" }}
                                        >
                                          <i className="text-danger p-3">
                                            <Icon path={mdiClose} size={1} />
                                          </i>
                                        </a>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                        <div className="rounded bg-success mt-2">
                          <TableContainer component={Paper} color="success">
                            <Table
                              size="small"
                              aria-label="a dense table"
                              sx={{ min: 650 }}
                            >
                              <TableBody>
                                <TableRow color={blue}>
                                  <TableCell>Total Items</TableCell>
                                  <TableCell> VAT</TableCell>
                                  <TableCell align="center">
                                    {" "}
                                    <OrderDiscount />
                                  </TableCell>
                                  <TableCell>Total Price</TableCell>
                                  <TableCell></TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>
                                    {getTotal()?.totalQuantity}
                                  </TableCell>
                                  <TableCell>
                                    {" "}
                                    {getTotal()?.VAT.toLocaleString()}
                                  </TableCell>
                                  <TableCell align="center">
                                    {savedOrderDiscount
                                      ? savedOrderDiscount.toLocaleString()
                                      : 0}
                                  </TableCell>
                                  <TableCell>
                                    <h5>
                                      {getTotal()?.overallPrice.toLocaleString()}
                                    </h5>
                                  </TableCell>
                                  <TableCell align="right">
                                    <button
                                      type="submit"
                                      className="btn btn-success"
                                    >
                                      Save order
                                    </button>
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </section>
          </div>
        </div>
      </div>
      <Footer />
      <ToastContainer position="top-right" />
    </div>
  );
}

export default CreateOrder;
