import React from "react";
import { Oval } from "react-loader-spinner";

function CustomLoader({ size, color }) {
  const defaultColor = "#4fa94d";
  const whiteColor = "";
  return (
    <>
      <Oval
        visible={true}
        height={size}
        width={size}
        color={color ? color : defaultColor}
        ariaLabel="oval-loading"
        wrapperStyle={{}}
        wrapperClass="justify-content-center "
      />
    </>
  );
}

export default CustomLoader;
