import React, { useEffect, useState } from "react";
import DateConverter from "../Components/DateConverter";
import Icon from "@mdi/react";
import { mdiCheckCircle, mdiDelete } from "@mdi/js";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../app/axiosInstance";
import { setSavedGoods } from "../../app/StockSlice";
import { toast } from "react-toastify";
import NotifyMsg from "../Components/NotifyMsg";
import ToastNetworkError from "../Components/ToastNetworkError";
import CustomLoader from "../Components/CustomLoader";
import API from "../../services/api/API";

function StockApproveData({
  column1Data,
  column2Data,
  column3Data,
  column4Data,
  dateAdded,
  itemID,
  remover,
}) {
  const token = useSelector((state) => state.authorization.token);
  const user = useSelector((state) => state.authorization.userData);
  const savedGoods = useSelector((state) => state.stockStore.savedGoods);
  const [stock, setStock] = useState();
  const [sendingData, setSendingData] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (savedGoods.length > 0) {
      setStock(savedGoods);
    }

    //fetching the goods and update the data
    const stockFetch = async () => {
      try {
        const response = await API.goodsFetch(token);
        setStock(response.data.result);
        dispatch(setSavedGoods(response.data.result));
      } catch (error) {
        // ToastNetworkError();
      }
    };
    stockFetch();
  }, []);

  //obtaining goods number from id
  const getItem = (itemId) => {
    if (stock?.length > 0) {
      const item = stock.filter((item) => item.id === parseInt(itemId));
      return item[0].goods_number;
    } else {
      return "Item Name..";
    }
  };

  //Approving the update
  const itemRemover = async () => {
    try {
      const data = {
        token,
        itemID,
      };
      const response = await API.deleteItem(data);
      if (response.status === 204) {
        toast.dismiss();
        toast.success("umekubari au kukataa ongezeko la bidhaa kikamirifu", {
          position: "top-right",
        });
        remover(itemID);
      }
    } catch (error) {
      ToastNetworkError();
    }
  };

  const approve = async () => {
    toast.dismiss();
    setSendingData(true);
    try {
      const data = {
        column1Data,
        column3Data,
        user,
        token,
      };
      const response = await API.approveItem(data);
      if (response.status === 201) {
        setSendingData(false);
        //removing the good from the temporary update
        itemRemover();
      }
    } catch (error) {
      ToastNetworkError();
      setSendingData(false);
    }
  };

  //notifying before action
  const approveNotification = () => {
    toast.dismiss();
    toast(
      <NotifyMsg
        Msg={"unaerekea kupitisha update ya bidhaa hii"}
        yesMethod={approve}
      />,
      { autoClose: false }
    );
  };

  const cancelNotification = () => {
    toast.dismiss();
    toast(
      <NotifyMsg
        Msg={"unakataa update ya bidhaa hii?"}
        yesMethod={itemRemover}
      />,
      { autoClose: false }
    );
  };

  return (
    <tr className="text-start text-wrap">
      <td>
        <img
          alt="table-image"
          src={require("../images/dashboard/ImageIcon.jpg")}
          style={{
            width: "4rem",
            height: "4rem",
            objectFit: "cover",
          }}
          className="rounded"
        />
      </td>
      <td className="text-start text-wrap">{DateConverter(dateAdded)}</td>
      <td className="text-start text-wrap">{getItem(column1Data)}</td>
      <td className="text-start text-wrap">{column2Data}</td>
      <td className="text-start text-wrap">{column3Data ? column3Data : 0}</td>
      <td className="text-start text-wrap">{column4Data}</td>
      <td className="text-start text-wrap">
        <div className="btn btn-group p-0 m-0">
          <a className="text-decoration-none btn" onClick={approveNotification}>
            <i className="text-success p-3">
              {sendingData ? (
                <CustomLoader size={10} />
              ) : (
                <Icon path={mdiCheckCircle} size={0.8} />
              )}
            </i>
          </a>
          <a
            className="text-decoration-none btn"
            name={column1Data}
            onClick={cancelNotification}
          >
            <i className="text-danger p-3">
              <Icon path={mdiDelete} size={0.8} />
            </i>
          </a>
        </div>
      </td>
    </tr>
  );
}

export default StockApproveData;
