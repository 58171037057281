import React from "react";

function PasswordValidation(pass) {
  if (pass.length < 8) {
    return false;
  }

  // Check for complexity
  const complexityRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/;
  if (!complexityRegex.test(pass)) {
    return false;
  }

  // Check against common patterns
  const commonPatternsRegex =
    /^(?=.*[a-zA-Z])(?=.*\d)(?!.*[\s])(?=.*[!@#$%^&*()_+}{":;'?/>.<,])(?!.*(.)\1\1)[a-zA-Z0-9!@#$%^&*()_+}{":;'?/>.<,]{8,}$/;
  if (!commonPatternsRegex.test(pass)) {
    return false;
  }
  return true;
}

export default PasswordValidation;
