import { mdiPercentCircle } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { setSavedOrderDiscount } from "../../app/AuthSlice";

function OrderDiscount() {
  const dispatch = useDispatch();
  const [errorFields, setErrorField] = useState({
    discount: true,
  });
  const [itemNumberData, setItemNumberData] = useState("0");
  const [orderDiscount, setOrderDiscount] = useState({
    order_discount: 0,
  });
  const [modal, setModal] = useState(false);
  const toggle = () => {
    if (modal) {
      setItemNumberData("0");
      setOrderDiscount({ ...orderDiscount, ["order_discount"]: 0 });
      setErrorField({ ...errorFields, ["discount"]: true });
    }
    setModal(!modal);
  };

  //handle input changes
  const handleChanges = (e) => {
    const { name, value } = e.target;
    setErrorField({ ...errorFields, [name]: true });
    const filteredValues = value
      .split(" ")
      .filter((item) => item !== "").length;
    let numericValue = value;
    if (filteredValues < 2) {
      numericValue = parseFloat(value.replace(/,/g, ""));
    }
    if (!isNaN(numericValue) || value === "") {
      const displayedData = value === "" ? "" : numericValue.toLocaleString();
      setItemNumberData(displayedData);
      setOrderDiscount({
        ...orderDiscount,
        ["order_discount"]: isNaN(numericValue) ? 0 : numericValue,
      });
    }
  };

  const handleSubmit = () => {
    //error Check
    if (orderDiscount["order_discount"] > 0) {
      dispatch(setSavedOrderDiscount(orderDiscount["order_discount"]));
      setModal(!modal);
    } else {
      setErrorField({ ...errorFields, ["discount"]: false });
      toast.error("kama order Haiitaji Punguzo Kata ili kuacha 0", {
        autoClose: true,
      });
    }
  };

  return (
    <div>
      <div>
        <a
          className="btn btn-sm px-2 mx-2 text-success bg-light border"
          onClick={toggle}
          style={{ cursor: "pointer" }}
        >
          Discount
        </a>
        <Modal
          isOpen={modal}
          toggle={toggle}
          centered
          modalTransition={{ timeout: 50 }}
          backdropTransition={{ timeout: 100 }}
        >
          <ModalHeader toggle={toggle}>
            <div>Order Discount</div>
          </ModalHeader>
          <ModalBody>
            <div className="d-inline-flex gap-3 pb-3 text-secondary">
              Weka punguzo utakalolifanya kwa order hii
            </div>
            <form className="py-3">
              <div className="d-inline-flex gap-2">
                <div className="form-group">
                  <label for="ItemPrice">
                    Discount{" "}
                    {!errorFields.discount && (
                      <span className="text-danger">required</span>
                    )}
                  </label>
                  <input
                    type="text"
                    min="0"
                    className="form-control"
                    id="orderDiscount"
                    name="discount"
                    value={itemNumberData && itemNumberData}
                    onChange={handleChanges}
                    placeholder="0"
                  />
                </div>
              </div>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button color="success" onClick={handleSubmit}>
              Confirm
            </Button>{" "}
            <Button color="secondary" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
}

export default OrderDiscount;
