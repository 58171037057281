import React, { useEffect, useState } from "react";
import { mdiStore } from "@mdi/js";
import Footer from "../Components/footer";
import PageHeader from "../Components/pageHeader";
import PopUp from "../Components/popUp";
import axiosInstance from "../../app/axiosInstance";
import { useSelector } from "react-redux";
import CustomLoader from "../Components/CustomLoader";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import ToastNetworkError from "../Components/ToastNetworkError";

function AddStore() {
  const token = useSelector((state) => state.authorization.token);
  const user = useSelector((state) => state.authorization.userData);
  const [isLoading, setIsLoading] = useState(true);
  const [staffs, setStaffs] = useState();
  const navigate = useNavigate();
  const [errorFields, setErrorField] = useState({
    storeName: true,
    location: true,
    supervisor: true,
    company: true,
  });
  const [newStore, setNewStore] = useState({
    storeName: "",
    location: "",
    company: "",
    supervisor: "",
  });

  useEffect(() => {
    const staffFetch = async () => {
      try {
        const response = await axiosInstance.get("/auth/user_registration/", {
          headers: {
            Authorization: `Token ${token}`,
          },
        });
        setStaffs(response.data);
        setIsLoading(false);
      } catch (error) {
        ToastNetworkError();
      }
    };
    staffFetch();
  }, []);

  //handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrorField({ ...errorFields, [name]: true });
    setNewStore({ ...newStore, [name]: value });
  };

  //handle dropDown
  const handleGoodsDropDown = (e) => {
    setNewStore({ ...newStore, ["supervisor"]: e.target.value });
  };

  const submitData = () => {
    const errorUpdates = { ...errorFields };
    const storeToPost = { ...newStore, ["company"]: user.user_company };

    const itemsValidation = () => {
      const keys = Object.keys(storeToPost);
      keys.map((item, index) => {
        const itemName = keys[index];

        storeToPost[itemName] !== ""
          ? (errorUpdates[itemName] = true)
          : (errorUpdates[itemName] = false);
      });
      setErrorField(errorUpdates);
      const errors = Object.keys(errorUpdates);
      return errors.some((error) => errorUpdates[error] === false);
    };

    if (!itemsValidation()) {
      const storePost = async () => {
        try {
          const response = await axiosInstance.post(
            "/stock/store/",
            {
              store: {
                store_name: storeToPost.storeName,
                company: storeToPost.company,
                store_location: storeToPost.location,
                store_supervisor: storeToPost.supervisor,
              },
            },
            {
              headers: {
                Authorization: `Token ${token}`,
              },
            }
          );
          if (response.status === 201) {
            navigate("/stores", { state: { shopAdded: true } });
          }
        } catch (error) {
          if (error.response.status === 400) {
            toast.error(
              "Jina la store ulilochagua tayari linatumika, jaribu jina lingine.",
              { autoClose: true }
            );
            setErrorField({ ...errorFields, ["storeName"]: false });
          } else {
            ToastNetworkError();
          }
        }
      };
      storePost();
    }
  };

  //Return false if no error at all

  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <PageHeader PageName="Stores" PageIcon={mdiStore} requireBack={true} />
        <div>
          <div className="">
            <div className="h4 px-2 text-secondary">Add new Store</div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <form className="forms-sample">
                  <div className="row p-3">
                    <div className="col-md-6 p-3">
                      <div className="form-group">
                        <label for="storeName">
                          Store name{" "}
                          {!errorFields.storeName && (
                            <span className="text-danger">required</span>
                          )}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="storeName"
                          name="storeName"
                          onChange={handleChange}
                          placeholder="Manzese"
                        />
                      </div>
                      <div className="form-group">
                        <label for="location">
                          Location{" "}
                          {!errorFields.location && (
                            <span className="text-danger">required</span>
                          )}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="location"
                          name="location"
                          onChange={handleChange}
                          placeholder="Manzese"
                        />
                      </div>
                      <div className="form-group">
                        <label for="itemUnit">
                          Select Store-Keeper{" "}
                          {isLoading && <CustomLoader size={20} />}
                          {!errorFields.supervisor && (
                            <span className="text-danger">required</span>
                          )}
                        </label>

                        <select
                          className="form-control"
                          id="item"
                          style={{ height: "3rem", width: "100%" }}
                          onClick={handleGoodsDropDown}
                          disabled={isLoading}
                        >
                          {!isLoading && (
                            <>
                              {staffs?.map((item, index) => (
                                <option key={index} value={item.username}>
                                  {item.username}
                                </option>
                              ))}
                            </>
                          )}
                        </select>
                      </div>
                      <div className="form-group">
                        <label for="storeNumber">Phone number</label>
                        <input
                          type="phone"
                          className="form-control"
                          id="storeName"
                          name="storeName"
                          placeholder="Add phone number"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="btn w-100 p-0 m-0">
                        <input
                          type="file"
                          className="d-none w-100"
                          id="new-item-img"
                        />
                        <label for="new-item-img" className="w-100">
                          <div
                            style={{
                              position: "relative",
                              display: "inline-block",
                            }}
                            className="w-100"
                          >
                            <img
                              src={require("../images/dashboard/ImageIcon.jpg")}
                              alt="Selected File"
                              id="fileImage"
                              style={{
                                height: "20rem",
                                width: "100%",
                                objectFit: "cover",
                                objectPosition: "center",
                              }}
                            />
                            <div
                              className="d-flex justify-content-center align-items-center"
                              style={{
                                position: "absolute",
                                top: "0",
                                left: "0",
                                height: "100%",
                                width: "100%",
                                background: "rgba(0,0,0,0.20)",
                                cursor: "pointer",
                              }}
                            >
                              <span className="h4 text-white">
                                Add Store image
                              </span>
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="text-center py-3">
                    <PopUp
                      popUpButtonLabelColor="success"
                      popUpButtonLabel="Ongeza Store"
                      popUpbody="Je uko Tayari kuongeza Store Mpya"
                      yesAction={submitData}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <ToastContainer position="top-right" />
    </div>
  );
}

export default AddStore;
